import React, { Component } from 'react'
import ShipmentEditPage from './ShipmentEditPage';
import Topbar from '../../Common/Topbar';

export default class ShipmentEdit extends Component {
    render() {
        return (
            <div class="container-fluid">
                <Topbar pageName="Teslimat Düzenle" />
                <div class="container-fluid border-white">
                    <ShipmentEditPage urlid={this.props.match.params.id} />
                </div>
            </div>
        )
    }
}