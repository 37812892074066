import React, { Component } from 'react'
import Topbar from '../../Common/Topbar.jsx'
import Homepage from './Homepage'

export default class Home extends Component {

	render() {
		return (
			<div className="container-fluid">
				<Topbar pageName="Anasayfa" />
				<div class="container-fluid border-white">
					<Homepage />
				</div>
			</div>
		)
	}
}
