import React from "react";
import { QRCode } from "react-qr-svg";
import * as moment from "moment";

export default class ComponentToPrintShipments extends React.Component {
	render() {
		return (
			<>
				<div id="print">
					{this.props.selectedRows.map((data) => {
						return (
							<div class="container" key={data.id}>
								<div class="d-flex align-items-start justify-content-start w-75 mb-3">
									<div class="col-3">
										<QRCode
											bgColor="#FFFFFF"
											fgColor="#000000"
											level="Q"
											value={"SHI " + data.id}
										/>
									</div>
									<div class="col-9 d-flex flex-column">
										<div>No: {data.id}</div>
										<div class="py-2">
											Kimden:{" "}
											{ data.source_user !== null
												? data.source_user.name
												: data.external_source_user_name !== null
												? data.external_source_user_name
												: "-"}
										</div>
										<div class="pb-2">Kime: {data.target_user.name}</div>
										<div>
											{moment(data.created_at).format("DD/MM/YYYY HH:mm")}
										</div>
									</div>
								</div>
								<div class="page-break" />
							</div>
						);
					})}
				</div>
			</>
		);
	}
}
