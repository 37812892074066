import React from 'react';
import Companiespage from './CompaniesPage';
import Topbar from '../../Common/Topbar';

class Companies extends React.Component {
	render() {
		return (
			<div class="container-fluid">
				<Topbar pageName="Kargo Şirketleri" />
				<div class="container-fluid border-white">
					<Companiespage />
				</div>
			</div>
		)
	}
}
export default Companies;