import React, { Component } from 'react'

export default class Widget extends Component {
    render() {
        return (
            <div className="col-sm-4 mt-3">
                <div className="card">
                    <div className="card-body">
                        <p className="card-text">{this.props.name}</p>
                        <p className="card-count">{this.props.count}</p>
                        <p className="card-text">{this.props.unit}</p>
                    </div>
                </div>
            </div>
        )
    }
}