import React from "react";
import Select from "react-select";
import TableEnvelopes from "./TableEnvelopes";
import { fetchAPIGetMethodWithParams } from "../../Common/fetchApi";
import ReactToPrint from "react-to-print";
import ComponentToPrintEnvelopes from "./ComponentToPrintEnvelopes";

class Envelopespage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			envelopeData: [],
			filterStatus: null,
			totalPages: null,
			forceUpdateTable: false,
			selectedRowIds: {},
		};

		this.selectInputRef = React.createRef();
		this.printContentRef = React.createRef();
	}

	getData = async (i = 0) => {
		let data = [];
		await fetchAPIGetMethodWithParams("/envelopes", {
			page: i + 1,
			status: this.state.filterStatus,
		}).then((response) => {
			if (response.status >= 200 && response.status < 299) {
				data = response.data;
				this.setState({
					envelopeData: data,
					totalPages: parseInt(response.headers["total-pages"]),
				});
			} else {
				alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`);
			}
		});
		return data;
	};

	handleFilterChange = (selectedOption) => {
		this.setState({
			filterStatus: selectedOption === null ? null : selectedOption.value,
		});
	};

	filter = () => {
		document.getElementById("resetFilter").hidden = false;
		this.setState({
			forceUpdateTable: !this.state.forceUpdateTable,
		});
	};

	dismissFilter = () => {
		document.getElementById("resetFilter").hidden = true;
		this.selectInputRef.current.select.clearValue();
		this.setState({
			filterStatus: null,
			forceUpdateTable: !this.state.forceUpdateTable,
		});
	};

	convertRows = (data) => {
		return data.map((value) => {
			return {
				id: value.id,
				status: this.getStatus(value.status),
			};
		});
	};

	getStatus = (value) => {
		switch (value) {
			case "available":
				return "Uygun";
			case "in_use":
				return "Kullanımda";
			case "not_available":
				return "Uygun Değil";
			default:
				return value;
		}
	};

	setSelectedRows = (ids) => {
		this.setState({
			selectedRowIds: ids,
		});
	};

	render() {
    console.log(this.state.envelopeData)
		const options = [
			{ value: "available", label: "Uygun" },
			{ value: "in_use", label: "Kullanımda" },
			{ value: "not_available", label: "Uygun Değil" },
		];

		return (
			<div class="row">
				<div class="col-sm-9 mb-5 mt-3 bg-white">
					<div>
						<ReactToPrint
							trigger={() => (
								<button type="button" class="btn btn-light my-3">
									Yazdır
								</button>
							)}
							content={() => this.printContentRef.current}
						/>
						<div style={{ display: "none" }}>
							<ComponentToPrintEnvelopes
								selectedRows={Object.keys(this.state.selectedRowIds)}
								ref={this.printContentRef}
							/>
						</div>
					</div>
					<TableEnvelopes
						getData={this.getData}
						pageCount={this.state.totalPages}
						convertRows={this.convertRows}
						forceUpdateTable={this.state.forceUpdateTable}
						id="myTable"
						setSelectedRows={this.setSelectedRows}
					/>
					<a
						href="/newenvelope"
						class="btn btn-success mt-3 mb-3 display-4"
						role="button"
					>
						Yeni Zarf
					</a>
				</div>
				<div class="col-sm-3 mt-3">
					<div class="card">
						<div class="card-body">
							<h6 class="card-title border-bottom">Filtre Seçenekleri</h6>
							<div class="mt-4">
								<h6>Statü</h6>
								<Select
									isClearable
									onChange={this.handleFilterChange}
									options={options}
									name="filterStatus"
									id="filterStatus"
									placeholder="Seç..."
									ref={this.selectInputRef}
								/>
								<div class="mt-3 text-center">
									<button
										class="btn btn-primary float-right"
										onClick={this.filter}
									>
										Filtrele
									</button>
								</div>
								<div class="mt-3 text-center">
									<button
										class="btn btn-warning float-left"
										onClick={this.dismissFilter}
										id="resetFilter"
										hidden
									>
										Filtreyi Kaldır
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Envelopespage;
