import React from 'react';
import Userinfopage from './Userinfopage';
import Topbar from '../../Common/Topbar';

class Userinfo extends React.Component {
	render() {
		return (
			<div class="container-fluid">
				<Topbar pageName="Kullanıcı Detay" />
				<div class="container-fluid border-white">
					<Userinfopage id={this.props.match.params.id} />
				</div>
			</div>
		)

	}
}
export default Userinfo;