import React from 'react';
import { FaBuilding, FaDolly } from "react-icons/fa";
import { fetchAPIGetMethod, fetchAPIGetMethodWithParams, fetchAPIPatchMethodWithParams, fetchAPIDeleteMethod } from '../../Common/fetchApi';
import Loader from '../../Common/Loader.jsx';

class CompanyEditPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			companyData: [],
			tempData: [],
			totalPages: null,
			id: null,
			name: null
		}
	}

	getHeaderInfo = async () =>{	
		return new Promise(resolve => {
			fetchAPIGetMethod('/companies/shipping')
				.then(response => {
					this.setState({totalPages : parseInt(response.headers["total-pages"])})
					resolve(true);
				})
			})
	}

	getData = async i => {
		return new Promise(resolve => {
			fetchAPIGetMethodWithParams('/companies/shipping', {
				page: i
			})
				.then(response => {
					if (response.status >= 200 && response.status < 299) {
						this.setState({ tempData: this.state.tempData.concat(response.data) })
						this.setState({
							companyData: this.state.tempData
						})
						resolve(true);
					}
					else if (response.status >= 300 && response.status < 500) {
						alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
					} 
					else {
						alert(`${response.status} - Bilinmeyen Hata`)
					}
				})
		})
	}

	result = async () => {
		for (let i = 1; i < this.state.totalPages+1; i++) {
			await this.getData(i);
		}
		this.setState({ isLoading: false })
	}

	async componentDidMount() {
		await this.getHeaderInfo()
		let companyId = parseInt(this.props.urlid);
		await this.result()

		this.state.companyData.forEach(item => {
			if (item.id === companyId) {
				this.setState({ id: item.id })
				this.setState({ name: item.name })
			}
		})
	}

	handleChange = e => {
		this.setState({[e.target.name]: e.target.value})
	}

	handleDelete = e => {
		e.preventDefault();
		const url = "/companies/shipping/" + this.state.id
		fetchAPIDeleteMethod(url)
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					window.location.href = "/companies"
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}			
			})
	}

	handleSubmit = event => {
		event.preventDefault();

		const url = '/companies/shipping/' + this.state.id
		fetchAPIPatchMethodWithParams(url, {
			name: this.state.name
		})
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					window.location.href = "/companies"
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			});
	}


	render() {
		const { isLoading } = this.state;
		if (isLoading) {
			return (
				<Loader />
			)
		}
		return (<>
			<div class="row mt-5">
				<div class="col-sm-6">
					<div class="card">
						<div class="card-body">
							<form class="form" onSubmit={this.handleSubmit}>
								<h6>Kargo Şirketi ID :</h6>
								<div class="input-group mb-3">
									<input type="text" name="id" value={this.state.id} class="form-control" disabled></input>
									<div class="input-group-append">
										<span class="input-group-text"><FaBuilding /></span>
									</div>
								</div>
								<h6>Adı :</h6>
								<div class="input-group mb-3">
									<input type="test" name="name" value={this.state.name} class="form-control" onChange={this.handleChange}></input>
									<div class="input-group-append">
										<span class="input-group-text"><FaDolly /></span>
									</div>
								</div> 
								<div class="text-center mt-3">
									<button class="btn btn-primary float-left" type="submit">Güncelle</button>
								</div>
							</form>
							<form onSubmit={this.handleDelete}>
								<input type="hidden" name="idd" class="form-control" value={this.state.id}></input>
								<button class="btn btn-danger float-right" type="submit">Sil</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
		)
	}
}

export default CompanyEditPage;