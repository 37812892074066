import axios from 'axios';

const BASE_URL=process.env.REACT_APP_BASE_URL;
const AUTH_NAME = process.env.REACT_APP_AUTH_NAME;
const AUTH_PASSWORD = process.env.REACT_APP_AUTH_PASSWORD;

let fetchAPIGetMethod = (url, handleError) => {
  return axios({
    method: 'get',
    url: url,
    baseURL: BASE_URL,
    headers: {
      'Authorization': "Bearer " + localStorage.getItem("access_token"),
      'accept': 'application/json'
    }
  })
  .then(data => {
    return data;
  })
  .catch(error => {
    if(error.response.status===401){
      fetchAPIPostMethod('/oauth/token', {
        refresh_token: localStorage.getItem('refresh_token'),
        grant_type: 'refresh_token'
      })
        .then(response => {
          if (response.status >= 200 && response.status < 299) {
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);  
          }
        });
    }
    return error.response;
  });
};

let fetchAPIGetMethodWithParams =(url, params, handleError) => {
  return axios({
    method: 'get',
    url: url,
    baseURL:  BASE_URL,
    headers: {
      'Authorization': "Bearer " + localStorage.getItem("access_token"),
      'accept': 'application/json',
    },
    params: params
  })
  .then(data => {
    return data;
  })
  .catch(error => {
    if(error.response.status===401){
      fetchAPIPostMethod('/oauth/token', {
        refresh_token: localStorage.getItem('refresh_token'),
        grant_type: 'refresh_token'
      })
        .then(response => {
          if (response.status >= 200 && response.status < 299) {
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);  
          }
        });
    }
    return error.response;
  });
};

let fetchAPIPostMethod = (url, body, handleError) => {
  return axios({
    method: 'post',
    url: url,
    baseURL: BASE_URL,
    auth: {
      username: AUTH_NAME,
      password: AUTH_PASSWORD
    },
    headers: {
      'accept': 'application/json'
    },
    data:body
  })
  .then(data => {
    return data;
  })
  .catch(error => {
    if(error.response.status===401){
      fetchAPIPostMethod('/oauth/token', {
        refresh_token: localStorage.getItem('refresh_token'),
        grant_type: 'refresh_token'
      })
        .then(response => {
          if (response.status >= 200 && response.status < 299) {
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);  
          }
        });
    }
    return error.response;
  });
};

let fetchAPIPostMethodWithParams = (url, params, handleError) => {
  return axios({
    method: 'post',
    url: url,
    baseURL: BASE_URL,
    headers: {
      'Authorization': "Bearer " + localStorage.getItem("access_token"),
      'accept': 'application/json'
    },
    data:params
  })
  .then(data => {
    return data;
  })
  .catch(error => {
    if(error.response.status===401){
      fetchAPIPostMethod('/oauth/token', {
        refresh_token: localStorage.getItem('refresh_token'),
        grant_type: 'refresh_token'
      })
        .then(response => {
          if (response.status >= 200 && response.status < 299) {
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);  
          }
        });
    }
    return error.response;
  });
};

let fetchAPIPatchMethodWithParams = (url, params, handleError) => {
  return axios({
    method: 'patch',
    url: url,
    baseURL: BASE_URL,
    headers: {
      'Authorization': "Bearer " + localStorage.getItem("access_token"),
      'accept': 'application/json'
    },
    data:params,
  })
  .then(data => {
    return data;
  })
  .catch(error => {
    if(error.response.status===401){
      fetchAPIPostMethod('/oauth/token', {
        refresh_token: localStorage.getItem('refresh_token'),
        grant_type: 'refresh_token'
      })
        .then(response => {
          if (response.status >= 200 && response.status < 299) {
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);  
          }
        });
    }
    return error.response;
  });
};

let fetchAPIDeleteMethod = (url, handleError) => {
  return axios({
    method: 'delete',
    url: url,
    baseURL: BASE_URL,
    headers: {
      'Authorization': "Bearer " + localStorage.getItem("access_token"),
      'accept': 'application/json'
    }
  })
  .then(data => {
    return data;
  })
  .catch(error => {
    if(error.response.status===401){
      fetchAPIPostMethod('/oauth/token', {
        refresh_token: localStorage.getItem('refresh_token'),
        grant_type: 'refresh_token'
      })
        .then(response => {
          if (response.status >= 200 && response.status < 299) {
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);  
          }
        });
    }
    return error.response;
  });
};

let fetchAPIDeleteMethodWithParams = (url, params, handleError) => {
  return axios({
    method: 'delete',
    url: url,
    baseURL: BASE_URL,
    headers: {
      'Authorization': "Bearer " + localStorage.getItem("access_token"),
      'accept': 'application/json'
    },
    data:params
  })
  .then(data => {
    return data;
  })
  .catch(error => {
    if(error.response.status===401){
      fetchAPIPostMethod('/oauth/token', {
        refresh_token: localStorage.getItem('refresh_token'),
        grant_type: 'refresh_token'
      })
        .then(response => {
          if (response.status >= 200 && response.status < 299) {
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);  
          }
        });
    }
    return error.response;
  });
};

export {
  fetchAPIGetMethod,
  fetchAPIGetMethodWithParams,
  fetchAPIPostMethod,
  fetchAPIPostMethodWithParams,
  fetchAPIDeleteMethod,
  fetchAPIDeleteMethodWithParams,
  fetchAPIPatchMethodWithParams,
};

