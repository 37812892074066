import React, { Component } from "react";
import Select from "react-select";
import TableShipments from "./TableShipments";
import { FaDolly } from "react-icons/fa";
import {
	fetchAPIPostMethodWithParams,
	fetchAPIGetMethod,
	fetchAPIGetMethodWithParams,
} from "../../Common/fetchApi";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactToPrint from "react-to-print";
import ComponentToPrintShipments from "./ComponentToPrintShipments";

export default class Shipmentspage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: null,
			envelope_id: null,
			shipping_company_id: null,
			organization_id: null,
			source_user_id: null,
			target_user_id: null,
			external_source_name: null,
			shipmentsData: {},
			tempData: [],
			totalPages: null,
			organizationData: [],
			isLoading: false,
			isInternal: true,
			canAddShipment: false,
			selectedRows: [],
      selectedOriginalRows: [],

			shipping_company_name_to_add: null,

			internal: null,
			confidential: null,
			filter_type: null,
			filter_status: null,
			filter_envelope_id: null,
			filter_target_user_id: null,
			filter_source_user_id: null,
			filter_shipping_company_id: null,
			filter_external_source_user_name: null,
			filter_postman_id: null,
			filter_creator_id: null,
			filter_created_at_start_date: null,
			filter_created_at_end_date: null,
			filter_delivered_at_start_date: null,
			filter_delivered_at_end_date: null,
			filter_transited_at_start_date: null,
			filter_transited_at_end_date: null,

			lastItem: null,

			forceUpdateTable: false,
		};

    this.printContentRef = React.createRef();
	}

  setSelectedRows = (rows) => {
    this.setState({
      selectedRows: rows,
      selectedOriginalRows: this.getOriginalRows(rows)
    })
  }

  getOriginalRows = (rows) => {
    let originalRows = []
    for (let k in rows) {
      if(!this.state.shipmentsData.hasOwnProperty(k)) { continue; }
      originalRows.push(this.state.shipmentsData[k])
    }

    return originalRows
  }

  convertData = (data) => {
    return Object.assign({}, this.state.shipmentsData, ...data.map((x) => ({[x.id]: x})));
  }

	// Initialization

	getData = async (i = 0) => {
		let data = [];
		await fetchAPIGetMethodWithParams("/shipments", {
			page: i + 1,
			internal: this.state.internal,
			confidential: this.state.confidential,
			status: this.state.filter_status,
			envelope_id: this.state.filter_envelope_id,
			target_user_name: this.state.filter_target_user_name,
			target_user_id: this.state.filter_target_user_id,
			source_user_name: this.state.filter_source_user_name,
			source_user_id: this.state.filter_source_user_id,
			external_source_user_name: this.state.filter_external_source_user_name,
			postman_id: this.state.filter_postman_id,
			creator_id: this.state.filter_creator_id,
			shipping_company_id: this.state.filter_shipping_company_id,
			created_at_gt: this.state.filter_created_at_start_date,
			created_at_lt: this.state.filter_created_at_end_date,
			delivered_at_gt: this.state.filter_delivered_at_start_date,
			delivered_at_lt: this.state.filter_delivered_at_end_date,
			transited_at_gt: this.state.filter_transited_at_start_date,
			transited_at_lt: this.state.filter_transited_at_end_date,
		}).then((response) => {
			if (response.status >= 200 && response.status < 299) {
				data = response.data;
				this.setState({
					shipmentsData: this.convertData(data),
					totalPages: parseInt(response.headers["total-pages"]),
					isLoading: false,
				});
			} else {
				alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`);
			}
		});
		return data;
	};

	async componentDidMount() {
		this.setState({ isLoading: true });
		this.fetchOrganizations();
	}

	fetchOrganizations = () => {
		fetchAPIGetMethod("/organizations").then((response) => {
			let tempOrganizationData = [];
			if (response.status >= 200 && response.status < 299) {
				for (let organization of response.data) {
					tempOrganizationData.push({
						label: organization.name,
						value: organization.id.toString(),
					});
				}
			} else if (response.status >= 300 && response.status < 500) {
				alert(`${response.status} Hatası: ${response?.data?.full_messages[0]}`);
			} else {
				alert(`${response.status} - Bilinmeyen Hata`);
			}
			this.setState({ organizationData: tempOrganizationData });
		});
	};

	checkAddButtonStatus = async () => {
		if (this.state.type === "external") {
			if (
				this.state.organization_id === null ||
				this.state.shipping_company_id === null ||
				this.state.external_source_user_name === null ||
				this.state.target_user_id === null ||
				this.state.shipping_company_id === "000"
			) {
				this.setState({ canAddShipment: false });
			} else {
				this.setState({ canAddShipment: true });
			}
		} else {
			if (
				this.state.organization_id === null ||
				this.state.envelope_id === null ||
				this.state.source_user_id === null ||
				this.state.target_user_id === null
			) {
				this.setState({ canAddShipment: false });
			} else {
				this.setState({ canAddShipment: true });
			}
		}
	};

	loadUserOptions = (inputValue, callback, role, isActive) => {
		if (!inputValue) {
			return callback([]);
		}

		fetchAPIGetMethodWithParams("/users", {
			active: isActive || null,
			name: inputValue,
			role: role,
		}).then((response) => {
			let tempUserData = [];
			if (response.status >= 200 && response.status < 299) {
				for (let user of response.data) {
					tempUserData.push({ label: user.name, value: user.id.toString() });
				}
			} else if (response.status >= 300 && response.status < 500) {
				alert(`${response.status} - Bilinmeyen Hata`);
			} else {
				alert(`${response.status} - Bilinmeyen Hata`);
			}
			callback(tempUserData);
		});
	};

	loadShippingCompanyOptions = (inputValue, callback, selector) => {
		if (!inputValue) {
			return callback([]);
		}
		fetchAPIGetMethodWithParams("/companies/shipping", {
			name: inputValue,
		}).then((response) => {
			let tempShippingCompanyData = [];
			if (response.status >= 200 && response.status < 299) {
				for (let user of response.data) {
					tempShippingCompanyData.push({
						label: user.name,
						value: user.id.toString(),
					});
				}
				if (selector === "new_shipment") {
					tempShippingCompanyData.push({ value: "000", label: "Diğer" });
				}
				this.setState({ lastItem: response.data[response.data.length - 1] });
			} else if (response.status >= 300 && response.status < 500) {
				alert(`${response.status} - Bilinmeyen Hata`);
			} else {
				alert(`${response.status} - Bilinmeyen Hata`);
			}
			callback(tempShippingCompanyData);
		});
	};

	// New Shipment Handles

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleChange = (selected, selector) => {
		this.setState({ [selector]: selected.value });
	};

	handleShipmentTypeChange = (selectedOption) => {
		let type = selectedOption.value;
		this.setState({ type });

		if (type === "internal" || type === "private") {
			this.setState({ isInternal: true });
		} else if (type === "external") {
			this.setState({ isInternal: false });
		}
	};

	// Create New Shipment

	handleSubmit = async () => {
		await this.checkAddButtonStatus();
		if (this.state.canAddShipment === true) {
			if (this.state.type === "external") {
				fetchAPIPostMethodWithParams("/shipments/external", {
					external_source_user_name: this.state.external_source_user_name,
					target_user_id: this.state.target_user_id,
					organization_id: this.state.organization_id,
					shipping_company_id: this.state.shipping_company_id,
					confidential: this.state.type === "private",
				}).then((response) => {
					if (response.status >= 200 && response.status < 299) {
						window.location.reload();
					} else {
						alert(
							`${response.status} Hatası: ${response?.data?.full_messages[0]}`
						);
					}
				});
			} else {
				fetchAPIPostMethodWithParams("/shipments/internal", {
					source_user_id: this.state.source_user_id,
					target_user_id: this.state.target_user_id,
					envelope_id: this.state.envelope_id,
					organization_id: this.state.organization_id,
					confidential: this.state.type === "private",
				}).then((response) => {
					if (response.status >= 200 && response.status < 299) {
						window.location.reload();
					} else {
						alert(
							`${response.status} Hatası: ${response?.data?.full_messages[0]}`
						);
					}
				});
			}
		} else {
			alert("Eksik bilgi girdiniz. Lütfen tüm alanları seçiniz.");
		}
	};

	// Add Shipping Company

	addShippingCompany = (e) => {
		e.preventDefault();
		fetchAPIPostMethodWithParams("/companies/shipping", {
			name: this.state.shipping_company_name_to_add,
		}).then((response) => {
			if (response.status >= 200 && response.status < 299) {
				this.setState({ shipping_company_id: null });
			} else if (response.status >= 300 && response.status < 500) {
				alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`);
			} else {
				alert(`${response.status} - Bilinmeyen Hata`);
			}
		});
	};

	// Filter handlers

	handleFilterTextQueryChange = (e) => {
		let value = e.target.value.length === 0 ? null : e.target.value;

		this.setState({ [e.target.name]: value });
	};

	handleFilterTypeChange = (selectedOption) => {
		if (selectedOption.value === "internal") {
			this.setState({
				internal: true,
				confidential: false,
			});
		} else if (selectedOption.value === "external") {
			this.setState({
				internal: false,
				confidential: false,
			});
		} else if (selectedOption.value === "private") {
			this.setState({
				internal: null,
				confidential: true,
			});
		}
	};

	handleFilterStatusChange = (selectedOption) => {
		let filter_status = selectedOption.value;
		this.setState({ filter_status });
	};

	dismissFilter = () => {
		document.getElementById("resetFilter").hidden = true;
		this.setState({
			internal: null,
			confidential: null,
			filter_status: null,
			filter_envelope_id: null,
			filter_target_user_id: null,
			filter_source_user_id: null,
			filter_shipping_company_id: null,
			filter_external_source_user_name: null,
			filter_postman_id: null,
			filter_creator_id: null,
			filter_created_at_start_date: null,
			filter_created_at_end_date: null,
			filter_delivered_at_start_date: null,
			filter_delivered_at_end_date: null,
			filter_transited_at_start_date: null,
			filter_transited_at_end_date: null,
			forceUpdateTable: !this.state.forceUpdateTable,
		});
	};

	filterByEnvelope = async () => {
		document.getElementById("resetFilter").hidden = false;
		this.setState({
			forceUpdateTable: !this.state.forceUpdateTable,
		});
	};

	handleDatePickerChange = (date, selector) => {
		this.setState({ [selector]: date || null });
	};

	// No options message for react-select

	returnMessage = (e) => {
		if (e.inputValue === "") {
			return "Filtrelemek için karakter girişi yapınız.";
		} else {
			return "Sonuç bulunamadı.";
		}
	};

	convertRows = (data) => {
		return data;
	};

	// Render

	render() {
		const shipment_options = [
			{ value: "internal", label: "İç" },
			{ value: "external", label: "Dış" },
			{ value: "private", label: "Özel" },
		];

		const status_options = [
			{ value: "received", label: "Alındı" },
			{ value: "in_transit", label: "Yolda" },
			{ value: "delivered", label: "İletildi" },
		];

		return (
			<>
				<div class="row">
					<div class="col-sm-9 mb-5 mt-3 bg-white">
						<div class="mt-5">
							<h6 class="float-left selector-names">Tür</h6>
							<h6 class="float-left selector-names">Şirket</h6>
							{this.state.isInternal ? (
								<>
									<h6 class="float-left selector-names">Zarf</h6>
									<h6 class="float-left selector-names">Kaynak</h6>
									<h6 class="float-left selector-names">Hedef</h6>
								</>
							) : (
								<>
									<h6 class="float-left selector-names">Kargo Şirketi</h6>
									<h6 class="float-left selector-names">Kaynak</h6>
									<h6 class="float-left selector-names">Hedef</h6>
								</>
							)}
						</div>
						<div class="mt-5">
							<div class="float-left type-select" id="type">
								<Select
									options={shipment_options}
									onChange={this.handleShipmentTypeChange}
									placeholder="Seç..."
								></Select>
							</div>
							<div class="ml-6 float-left type-select" id="type">
								<Select
									options={this.state.organizationData}
									onChange={(values) =>
										this.handleChange(values, "organization_id")
									}
									placeholder="Seç..."
								></Select>
							</div>
							{this.state.isInternal ? (
								<>
									<div class="float-left envelope-select" id="envelope">
										<input
											class="form-control"
											name="envelope_id"
											value={
												this.state.envelope_id === null
													? " "
													: this.state.envelope_id
											}
											onChange={this.onChange}
										></input>
									</div>
									<div class="float-left source-select" id="source">
										<AsyncSelect
											placeholder="Seç..."
											loadOptions={(inputValue, callback) =>
												this.loadUserOptions(inputValue, callback, null, "true")
											}
											onChange={(values) =>
												this.handleChange(values, "source_user_id")
											}
											noOptionsMessage={this.returnMessage}
										/>
									</div>
									<div class="float-left target-select" id="target">
										<AsyncSelect
											placeholder="Seç..."
											loadOptions={(inputValue, callback) =>
												this.loadUserOptions(inputValue, callback, null, "true")
											}
											onChange={(values) =>
												this.handleChange(values, "target_user_id")
											}
											noOptionsMessage={this.returnMessage}
										/>
									</div>
								</>
							) : (
								<>
									<div
										class="float-left shipping-company-select"
										id="shipping_company_id"
									>
										<AsyncSelect
											placeholder="Seç..."
											loadOptions={(inputValue, callback) =>
												this.loadShippingCompanyOptions(
													inputValue,
													callback,
													"new_shipment"
												)
											}
											onChange={(values) =>
												this.handleChange(values, "shipping_company_id")
											}
											noOptionsMessage={this.returnMessage}
										/>
									</div>
									<div
										class="float-left"
										id="ext_source"
										style={{ marginLeft: "2%", width: "15%" }}
									>
										<input
											class="form-control"
											name="external_source_user_name"
											onChange={this.onChange}
										></input>
									</div>
									<div class="float-left target-select" id="target">
										<AsyncSelect
											placeholder="Seç..."
											loadOptions={this.loadUserOptions}
											onChange={(values) =>
												this.handleChange(values, "target_user_id")
											}
											noOptionsMessage={this.returnMessage}
										/>
									</div>
								</>
							)}
							<button
								type="button"
								class="btn btn-success float-right mb-5"
								id="addShipmentButton"
								onClick={this.handleSubmit}
							>
								Ekle
							</button>
						</div>
						{this.state.shipping_company_id === "000" &&
						this.state.type === "external" ? (
							<>
								<div class="row">
									<div class="col-sm-9 ml-3 mb-5">
										<form class="form" onSubmit={this.addShippingCompany}>
											<div class="input-group">
												<input
													name="shipping_company_name_to_add"
													class="form-control"
													placeholder="Kargo Şirketi Adı"
													required
													onChange={this.onChange}
												></input>
												<div class="input-group-append">
													<span class="input-group-text">
														<FaDolly />
													</span>
												</div>
												<button
													class="btn btn-success float-left ml-5"
													type="submit"
												>
													Kargo Şirketi Ekle
												</button>
											</div>
										</form>
									</div>
								</div>
							</>
						) : null}
						<div className="d-flex flex-column w-100">
							<div>
								<ReactToPrint
									trigger={() => (
										<button type="button" class="btn btn-light my-3">
											Yazdır
										</button>
									)}
									content={() => this.printContentRef.current}
									className="w-100"
								/>
							</div>

							<div style={{ display: "none" }}>
								<ComponentToPrintShipments
									selectedRows={this.state.selectedOriginalRows}
									ref={this.printContentRef}
								/>
							</div>
							<TableShipments
								getData={this.getData}
								pageCount={this.state.totalPages}
								convertRows={this.convertRows}
								forceUpdateTable={this.state.forceUpdateTable}
								id="myTable"
                setSelectedRows={this.setSelectedRows}
							/>
						</div>
						<div class="mt-5"></div>
					</div>
					<div class="col-sm-3 mt-3">
						<div class="card">
							<div class="card-body">
								<h6 class="card-title border-bottom">Filtre Seçenekleri</h6>
								<div class="mt-2">
									<h6>Tür</h6>
									<Select
										options={shipment_options}
										onChange={this.handleFilterTypeChange}
										placeholder="Seç..."
									></Select>
								</div>
								<div class="mt-2">
									<h6>Statü</h6>
									<Select
										options={status_options}
										onChange={this.handleFilterStatusChange}
										placeholder="Seç..."
									></Select>
								</div>
								<div class="mt-2">
									<h6>Zarf</h6>
									<input
										class="form-control"
										name="filter_envelope_id"
										onChange={this.handleFilterTextQueryChange}
									></input>
								</div>
								<div class="mt-2">
									<h6>Kaynak</h6>
									<AsyncSelect
										placeholder="Seç..."
										loadOptions={this.loadUserOptions}
										onChange={(values) =>
											this.handleChange(values, "filter_source_user_id")
										}
										noOptionsMessage={this.returnMessage}
									/>
								</div>
								<div class="mt-2">
									<h6>Hedef</h6>
									<AsyncSelect
										placeholder="Seç..."
										loadOptions={this.loadUserOptions}
										onChange={(values) =>
											this.handleChange(values, "filter_target_user_id")
										}
										noOptionsMessage={this.returnMessage}
									/>
								</div>
								<div class="mt-2">
									<h6>Kaynak (Dış)</h6>
									<input
										class="form-control"
										name="filter_external_source_user_name"
										onChange={this.handleFilterTextQueryChange}
									></input>
								</div>
								<div class="mt-2">
									<h6>Postacı</h6>
									<AsyncSelect
										placeholder="Seç..."
										loadOptions={(inputValue, callback) =>
											this.loadUserOptions(inputValue, callback, "postman")
										}
										onChange={(values) =>
											this.handleChange(values, "filter_postman_id")
										}
										noOptionsMessage={this.returnMessage}
									/>
								</div>
								<div class="mt-2">
									<h6>Oluşturan</h6>
									<AsyncSelect
										placeholder="Seç..."
										loadOptions={(inputValue, callback) =>
											this.loadUserOptions(
												inputValue,
												callback,
												"admin,agent,secretary"
											)
										}
										onChange={(values) =>
											this.handleChange(values, "filter_creator_id")
										}
										noOptionsMessage={this.returnMessage}
									/>
								</div>
								<div class="mt-2">
									<h6>Kargo Şirketi</h6>
									<AsyncSelect
										placeholder="Seç..."
										loadOptions={this.loadShippingCompanyOptions}
										onChange={(values) =>
											this.handleChange(values, "filter_shipping_company_id")
										}
										noOptionsMessage={this.returnMessage}
									/>
								</div>
								<div class="mt-2">
									<h6>Oluşturma Tarihi</h6>
									<div className="">
										<DatePicker
											selected={this.state.filter_created_at_start_date}
											onChange={(values) =>
												this.handleDatePickerChange(
													values,
													"filter_created_at_start_date"
												)
											}
											placeholderText="Başlangıç"
											selectsStart
											startDate={this.state.filter_created_at_start_date}
											endDate={this.state.filter_created_at_end_date}
											dateFormat="dd/MM/yyyy"
											className="form-control"
										/>
										<DatePicker
											selected={this.state.filter_created_at_end_date}
											onChange={(values) =>
												this.handleDatePickerChange(
													values,
													"filter_created_at_end_date"
												)
											}
											placeholderText="Bitiş"
											selectsEnd
											startDate={this.state.filter_created_at_start_date}
											endDate={this.state.filter_created_at_end_date}
											minDate={this.state.filter_created_at_start_date}
											dateFormat="dd/MM/yyyy"
											className="form-control"
										/>
									</div>
								</div>
								<div class="mt-2">
									<h6>Teslim Alma Tarihi</h6>
									<DatePicker
										selected={this.state.filter_transited_at_start_date}
										onChange={(values) =>
											this.handleDatePickerChange(
												values,
												"filter_transited_at_start_date"
											)
										}
										placeholderText="Başlangıç"
										selectsStart
										startDate={this.state.filter_transited_at_start_date}
										endDate={this.state.filter_transited_at_end_date}
										dateFormat="dd/MM/yyyy"
										className="form-control"
									/>
									<DatePicker
										selected={this.state.filter_transited_at_end_date}
										onChange={(values) =>
											this.handleDatePickerChange(
												values,
												"filter_transited_at_end_date"
											)
										}
										placeholderText="Bitiş"
										selectsEnd
										startDate={this.state.filter_transited_at_start_date}
										endDate={this.state.filter_transited_at_end_date}
										minDate={this.state.filter_transited_at_start_date}
										dateFormat="dd/MM/yyyy"
										className="form-control"
									/>
								</div>
								<div class="mt-2 fluid">
									<h6>Teslim Etme Tarihi</h6>
									<DatePicker
										selected={this.state.filter_delivered_at_start_date}
										onChange={(values) =>
											this.handleDatePickerChange(
												values,
												"filter_delivered_at_start_date"
											)
										}
										placeholderText="Başlangıç"
										selectsStart
										startDate={this.state.filter_delivered_at_start_date}
										endDate={this.state.filter_delivered_at_end_date}
										dateFormat="dd/MM/yyyy"
										className="form-control"
									/>
									<DatePicker
										selected={this.state.filter_delivered_at_end_date}
										onChange={(values) =>
											this.handleDatePickerChange(
												values,
												"filter_delivered_at_end_date"
											)
										}
										placeholderText="Bitiş"
										selectsEnd
										startDate={this.state.filter_delivered_at_start_date}
										endDate={this.state.filter_delivered_at_end_date}
										minDate={this.state.filter_delivered_at_start_date}
										dateFormat="dd/MM/yyyy"
										className="form-control"
									/>
								</div>
								<div class="mt-2 text-center">
									<button
										class="btn btn-primary float-right"
										onClick={this.filterByEnvelope}
									>
										Filtrele
									</button>
								</div>
								<div class="mt-2 text-center">
									<button
										class="btn btn-warning float-left"
										onClick={this.dismissFilter}
										id="resetFilter"
										hidden
									>
										Filtreyi Kaldır
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
