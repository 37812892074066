import React from 'react';
import { FaEnvelope } from "react-icons/fa";
import { FaShieldAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { fetchAPIPostMethodWithParams } from '../../Common/fetchApi'

class Userspage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			email: null,
			id: null,
			name: null,
			isChecked: false
		}
	}

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value })
	}

	handleChecked = e => {
		this.setState({ isChecked : !this.state.isChecked })
	}

	submitForm = e => {
		e.preventDefault()

		var redirect_url = process.env.REACT_APP_BACKOFFICE_URL+"/password/reset" 
		
		fetchAPIPostMethodWithParams('/users', {
			name: this.state.name,
			email: this.state.email,
			tekfen_id: this.state.id,
			send_password_reset: this.state.isChecked,
			redirect_url: redirect_url
		})
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					window.location.href = "/users"
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			});
	}
	render() {
		return (<>
			<div class="row mt-5">
				<div class="col-sm-6">
					<div class="card">
						<div class="card-body">
							<form class="form" onSubmit={this.submitForm}>
								<div class="input-group">
									<input type="text" name="email" class="form-control" placeholder="E-posta" required onChange={this.onChange}></input>
									<div class="input-group-append">
										<span class="input-group-text"><FaEnvelope /></span>
									</div>
								</div>
								<div class="input-group mt-4">
									<input type="test" name="id" class="form-control" placeholder="Tekfen Kart ID" required onChange={this.onChange}></input>
									<div class="input-group-append">
										<span class="input-group-text"><FaShieldAlt /></span>
									</div>
								</div>
								<div class="input-group mt-4">
									<input type="text" name="name" class="form-control" placeholder="Adı" required onChange={this.onChange}></input>
									<div class="input-group-append">
										<span class="input-group-text"><FaUserAlt /></span>
									</div>
								</div>
								{this.state.isChecked
									? <div class="form-check mt-3">
										<input type="checkbox" name="sendEmail" class="form-check-input" id="sendEmail" onClick={this.handleChecked} checked/>
										<label class="form-check-label" for="sendEmail">E-posta Gönder</label>
									</div>
									: <div class="form-check mt-3">
										<input type="checkbox" name="sendEmail" class="form-check-input" id="sendEmail" onChange={this.handleChecked}/>
										<label class="form-check-label" for="sendEmail">E-posta Gönder</label>
									</div>
								}
								<div class="text-center mt-3">
									<button class="btn btn-success float-left" type="submit">Kullanıcı Ekle</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
		)
	}
}

export default Userspage;