import React from 'react';
import ActionButtons from '../../Common/ActionButtons';
import ReactTableWrapper from '../../Common/ReactTableWrapper';

function TableUsers({ data, getData, pageCount, convertRows, forceUpdateTable }) {

	const columns = [
		{
			Header: 'Adı',
			accessor: 'name',
		},
		{
			Header: 'Aktiflik',
			accessor: d => d.active,
			Cell: ({ cell: { value } }) => {
				if(value === true) {
					return (
						<span>Aktif</span>
					);
				} else {
					return (
						<span>Pasif</span>
					);
				}
			}
		},
		{
			Header: 'E-posta',
			accessor: 'email',
		},
		{
			Header: 'Tekfen Kart ID',
			accessor: 'tekfen_id',
		},
		{
			id: 'linkID', //required because accessor is not a string
			Header: '',
			accessor: d => d.id,
			Cell: ({ cell: { value } }) => {
				return (
					<>
						<ActionButtons linkEye={'/userinfo/' + value} linkEdit={'/useredit/' + value} />
					</>
				);
			}
		},
	]

	return (
		<ReactTableWrapper
			data={data}
			columns={columns}
			getData={getData}
			pageCount={pageCount}
			convertRows={convertRows}
			forceUpdateTable={forceUpdateTable}
		/>
	)
}

export default TableUsers;