import React from 'react';
import { BrowserRouter, Switch, Route, NavLink } from 'react-router-dom';
import { createBrowserHistory as createHistory } from "history"
import PrivateRoute from './PrivateRoute'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import './App.css';

import Login from './Components/Login/Login'
import ForgotPassword from './Components/Login/ForgotPassword'
import PasswordReset from './Components/Login/PasswordReset'
import Logout from './Components/Login/Logout'

import Home from './Components/Home/Home'
import Profile from './Components/Profile/Profile';

import Users from './Components/Users/Users'
import Newuser from './Components/Users/Newuser';
import Userinfo from './Components/Users/Userinfo';
import UserEdit from './Components/Users/UserEdit';

import Companies from './Components/Companies/Companies';
import Newcompany from './Components/Companies/Newcompany';
import Companyinfo from './Components/Companies/Companyinfo';
import CompanyEdit from './Components/Companies/CompanyEdit';

import Envelopes from './Components/Envelopes/Envelopes';
import Newenvelope from './Components/Envelopes/Newenvelope';
import Envelopeinfo from './Components/Envelopes/Envelopeinfo';
import EnvelopeEdit from './Components/Envelopes/EnvelopeEdit';

import Shipments from './Components/Shipments/Shipments'
import Shipmentinfo from './Components/Shipments/Shipmentinfo';
import ShipmentEdit from './Components/Shipments/ShipmentEdit';

import Loader from './Common/Loader.jsx';

import { GoDashboard } from "react-icons/go";
import { FaUserAlt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaTruck } from "react-icons/fa";
import { FaDolly } from "react-icons/fa";
import logo from './Images/Tekfen_Holding_EN-02.svg';

const history = createHistory();

class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			userRoles: []
		}
	}

	componentDidMount() {
		this.setRoles()
	}

	setRoles() {
		const storedUserRoles = JSON.parse(localStorage?.getItem('user'))?.roles
		if (storedUserRoles) {
			const tempRoles = []
			storedUserRoles.map(role => {
				return tempRoles.push(role.name)
			})
			this.setState({ userRoles: tempRoles })
		}
		this.setState({ isLoading: false })
	}

	checkRole = (allowedRoles) => {
		let isAllowed = false
		allowedRoles.map(allowedRole => {
			if (isAllowed === true) {
				return isAllowed
			}
			return isAllowed = this.state.userRoles.includes(allowedRole)
		})
		return isAllowed
	}

	render() {

		const { isLoading } = this.state;
		if (isLoading) {
            return (
                <Loader />
            )
		}
		return (
			<BrowserRouter history={history}>
				<Switch>
					<Route exact path="/" component={Login} />
					<Route exact path="/password/forgot" component={ForgotPassword} />
					<Route exact path="/password/reset" component={PasswordReset} />
					<Route exact path="/logout" component={Logout} />
					<div class="d-flex wrapper">
						<div class="border-right" id="sidebar-wrapper">
							<div class="sidebar-heading text-center">
								<img src={logo} class="logo-new" alt="logo"></img>
							</div>

							<div class="list-group list-group-flush mt-3">
								<NavLink to="/home" className="nav-item list-group-item-action" activeClassName="selected-nav-item" hidden={!this.checkRole(["admin", "agent"])}>
									<div class="row">
										<div class="col-sm-3 text-right "><GoDashboard /></div>
										<div class="col-sm-9">Anasayfa</div>
									</div>
								</NavLink>
								<NavLink to="/users" className="nav-item list-group-item-action" activeClassName="selected-nav-item" hidden={!this.checkRole(["admin", "agent"])}>
									<div class="row">
										<div class="col-sm-3 text-right"><FaUserAlt /></div>
										<div class="col-sm-9 title">Kullanıcılar</div>
									</div>
								</NavLink>
								<NavLink to="/companies" className="nav-item list-group-item-action" activeClassName="selected-nav-item" hidden={!this.checkRole(["admin", "agent"])}>
									<div class="row">
										<div class="col-sm-3 text-right"><FaDolly /></div>
										<div class="col-sm-9">Kargo Şirketleri</div>
									</div>
								</NavLink>
								<NavLink to="/envelopes" className="nav-item list-group-item-action" activeClassName="selected-nav-item" hidden={!this.checkRole(["admin", "agent"])}>
									<div class="row">
										<div class="col-sm-3 text-right"><FaEnvelope /></div>
										<div class="col-sm-9">Zarflar</div>
									</div>
								</NavLink>
								<NavLink to="/shipments" className="nav-item list-group-item-action" activeClassName="selected-nav-item" hidden={!this.checkRole(["admin", "agent", "secretary", "postman"])}>
									<div class="row">
										<div class="col-sm-3 text-right"><FaTruck /></div>
										<div class="col-sm-9">Teslimatlar</div>
									</div>
								</NavLink>
							</div>
						</div>

						<PrivateRoute exact path="/home" component={Home} allowed={this.checkRole(["admin", "agent"])} />
						<PrivateRoute exact path="/profile" component={Profile} allowed={this.checkRole(["admin", "agent", "secretary", "postman"])} />

						<PrivateRoute exact path="/users" component={Users} allowed={this.checkRole(["admin", "agent"])} />
						<PrivateRoute exact path='/userinfo/:id' component={Userinfo} allowed={this.checkRole(["admin", "agent"])} />
						<PrivateRoute exact path='/useredit/:id' component={UserEdit} allowed={this.checkRole(["admin", "agent"])} />
						<PrivateRoute exact path='/newuser' component={Newuser} allowed={this.checkRole(["admin", "agent"])} />

						<PrivateRoute exact path="/companies" component={Companies} allowed={this.checkRole(["admin", "agent"])} />
						<PrivateRoute exact path='/companyinfo/:id' component={Companyinfo} allowed={this.checkRole(["admin", "agent"])} />
						<PrivateRoute exact path='/companyedit/:id' component={CompanyEdit} allowed={this.checkRole(["admin", "agent"])} />
						<PrivateRoute exact path='/newcompany' component={Newcompany} allowed={this.checkRole(["admin", "agent"])} />

						<PrivateRoute exact path='/envelopes' component={Envelopes} allowed={this.checkRole(["admin", "agent"])} />
						<PrivateRoute exact path='/envelopeinfo/:id' component={Envelopeinfo} allowed={this.checkRole(["admin", "agent"])} />
						<PrivateRoute exact path='/envelopeedit/:id' component={EnvelopeEdit} allowed={this.checkRole(["admin", "agent"])} />
						<PrivateRoute exact path='/newenvelope' component={Newenvelope} allowed={this.checkRole(["admin", "agent"])} />

						<PrivateRoute exact path='/shipments' component={Shipments} allowed={this.checkRole(["admin", "agent", "secretary", "postman"])} />
						<PrivateRoute exact path='/shipmentinfo/:id' component={Shipmentinfo} allowed={this.checkRole(["admin", "agent", "secretary", "postman"])} />
						<PrivateRoute exact path='/shipmentedit/:id' component={ShipmentEdit} allowed={this.checkRole(["admin", "agent", "secretary", "postman"])} />
					</div>
				</Switch>
			</BrowserRouter>
		)
	}
	
}
export default App;