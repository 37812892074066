import React, { Component } from 'react'
import Loader from '../../Common/Loader.jsx';
import { fetchAPIGetMethod } from '../../Common/fetchApi';
import * as moment from 'moment';
import 'moment/locale/tr';

export default class Shipmentinfopage extends Component {
  constructor(props) {
		super(props);
		this.state = {
      id: null,
      status: null,
      type: null,
      envelope_id: null,
      shipping_company: null,
      target_user_name: null,
      target_user_active: null,
      source_user_name: null,
      source_user_active: null,
      external_source_user_name: null,
      carrier_name: null,
      carrier_active: null,
      receiver_name: null,
      receiver_active: null,
      sender_name: null,
      sender_active: null,
      creator_name: null,
      creator_active: null,
      delivered_at: null,
      transited_at: null,
      created_at: null,
      updated_at: null,
      internal: null,
      confidential: null,
		  isLoading: true
		};
	}
	
	componentDidMount() {
    let url = '/shipments/'+this.props.urlid;
		fetchAPIGetMethod(url)
		  .then(response => {
			  if (response.status >= 200 && response.status < 299) {
          this.setState({ 
            id: response.data?.id || "-",
            status: response.data?.status || "-",
            envelope_id: response.data?.envelope_id || "-",
            target_user_name: response.data.target_user?.name || "-",
            target_user_active: response.data.target_user?.active,
            shipping_company: response.data.shipping_company?.name || "-",
            carrier_name: response.data.carrier?.name || "-",
            carrier_active: response.data.carrier?.active,
            receiver_name: response.data.receiver?.name || "-",
            receiver_active: response.data.receiver?.active,
            sender_name: response.data.sender?.name || "-",
            sender_active: response.data.sender?.active,
            creator_name: response.data.creator?.name || "-",
            creator_active: response.data.creator?.active,
            delivered_at: response.data?.delivered_at || null,
            transited_at: response.data?.transited_at || null,
            created_at: response.data?.created_at || null,
            updated_at: response.data?.updated_at || null,
            internal: response.data?.internal,
            confidential: response.data?.confidential
          })
          this.setState({ isLoading: false })
          
          if(this.state.internal===false && this.state.confidential===false){
            this.setState({ 
              source_user_name: response.data?.external_source_user_name || "-",
              type: "external"
            })
            document.getElementById('env_name').style.display = "none";
            document.getElementById('env_data').style.display = "none";
          }
          else if(this.state.internal===true && this.state.confidential===false){
            this.setState({ 
              source_user_name: response.data.source_user?.name || "-",
              source_user_active: response.data.source_user?.active,
              type: "internal"
            })
          }
          else{
            this.setState({ 
              source_user_name: response.data.source_user?.name || "-",
              source_user_active: response.data.source_user?.active,
              type: "private"
            })
          }
        }
        else {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				}
		  })
    }

    boolToLocalizedString = (condition) => {
      if (condition === false) {
        return "(Pasif)"
      } else {
        return ""
      }
    }

    render() {      
      if (this.state.isLoading) {
        return (
          <Loader />
        )
      }
      moment.locale('tr');
      moment.updateLocale('tr', {
        invalidDate : ' - '
      });

      return (
                <div class="row mt-5">
                  <div class="col-sm-7">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title border-bottom">Teslimat Detayları</h5>
                        <div class="row">
                          <div class="col-sm-6">
                            <p>ID : </p>
                            <p>Statü : </p>
                            <p>Tür : </p>
                            <p id="env_name">Zarf : </p>
                            <p>Kargo Şirketi : </p>
                            <p>Hedef : </p>
                            <p>Kaynak : </p>
                            <p>Postacı : </p>
                            <p>Alıcı : </p>
                            <p>Gönderen : </p>
                            <p>Oluşturan : </p>
                            <p>Teslim Tarihi : </p>
                            <p>Taşıma Tarihi : </p>
                            <p>Oluşturma Tarihi : </p>
                            <p>Güncelleme Tarihi : </p>
                          </div>
                          <div class="col-sm-6">
                            <p>{this.state.id}</p>
                            <p>
                                {(() => {
                                    switch (this.state.status) {
                                    case "received":   return "Alındı";
                                    case "in_transit": return "Yolda";
                                    case "delivered":  return "İletildi";
                                    default:      return this.state.status;
                                    }
                                })()}
                            </p>
                            <p>
                                {(() => {
                                    switch (this.state.type) {
                                    case "internal": return "İç";
                                    case "external": return "Dış";
                                    case "private":  return "Özel";
                                    default:      return this.state.type;
                                    }
                                })()}
                            </p>
                            <p id="env_data">{this.state.envelope_id}</p>
                            <p>{this.state.shipping_company}</p>
                            <p>{this.state.target_user_name} {this.boolToLocalizedString(this.state.target_user_active !== false)}</p>
                            <p>{this.state.source_user_name} {this.boolToLocalizedString(this.state.source_user_active !== false)}</p>
                            <p>{this.state.carrier_name} {this.boolToLocalizedString(this.state.carrier_active !== false)}</p>
                            <p>{this.state.receiver_name} {this.boolToLocalizedString(this.state_receiver_active !== false)}</p>
                            <p>{this.state.sender_name} {this.boolToLocalizedString(this.state.sender_active !== false)}</p>
                            <p>{this.state.creator_name} {this.boolToLocalizedString(this.state.creator_active !== false)}</p>
                            <p>{moment(this.state.delivered_at).format('DD MMMM YYYY - HH:mm:ss')}</p>
                            <p>{moment(this.state.transited_at).format('DD MMMM YYYY - HH:mm:ss')}</p>
                            <p>{moment(this.state.created_at).format('DD MMMM YYYY - HH:mm:ss')}</p>
                            <p>{moment(this.state.updated_at).format('DD MMMM YYYY - HH:mm:ss')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            )
        }
}