import React, { Component } from 'react'
import Shipmentspage from './Shipmentspage.jsx'
import Topbar from '../../Common/Topbar.jsx'

export default class Shipments extends Component {
	render() {
		return (
			<div class="container-fluid">
				<Topbar pageName="Teslimatlar" />
				<div class="container-fluid border-white">
					<Shipmentspage />
				</div>
			</div>
		)
	}
}
