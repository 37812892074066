import React, { Component } from 'react'
import { FaBox } from "react-icons/fa";
import { fetchAPIGetMethod, fetchAPIGetMethodWithParams, fetchAPIDeleteMethod, fetchAPIPatchMethodWithParams } from '../../Common/fetchApi';
import Loader from '../../Common/Loader.jsx';
import AsyncSelect from 'react-select/async';

export default class ShipmentEditPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			shipmentData: [],
			id: null,
			envelope_id: null,
			target_user_name: null,
			target_user_id: null,
			source_user_name: null,
			source_user_id: null,
			external_source_user_name: null,
			internal: null,
			envelopeData: [],
			userData: [],
			isLoading: true,  
			inputValue: ''
		}
		this.myRef = new React.createRef()
	}

	componentDidMount() {
		let url = '/shipments/'+this.props.urlid;
		fetchAPIGetMethod(url)
		.then(response => {
			if (response.status >= 200 && response.status < 299) {
				this.setState({
					id: response.data?.id,
					envelope_id: response.data?.envelope_id,
					target_user_name: response.data.target_user?.name,
					target_user_id: response.data.target_user?.id,
					source_user_name: response.data.source_user?.name,
					source_user_id: response.data.source_user?.id,
					external_source_user_name: response.data?.external_source_user_name,
					internal: response.data.internal
				})
			}
			else if (response.status >= 300 && response.status < 500) {
				alert(`${response.status} Hatası: ${response?.data?.full_messages[0]}`)
			} 
			else {
				alert(`${response.status} - Bilinmeyen Hata`)
			}	
		})
		this.setState({ isLoading: false })
	}

	handleChange = (selected,selector) => {
		this.setState({[selector]: selected.value})
	}

	handleTextChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	handleSubmit = event => {
		event.preventDefault();

		const url = "/shipments/" + this.props.urlid

		if (this.state.internal === true) {
			fetchAPIPatchMethodWithParams(url, {
				envelope_id: this.state.envelope_id,
				source_user_id: this.state.source_user_id,
				target_user_id: this.state.target_user_id
			})
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					window.location.href = "/shipments"
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			});
		} else {
			fetchAPIPatchMethodWithParams(url, {
				external_source_user_name: this.state.external_source_user_name,
				target_user_id: this.state.target_user_id
			})
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					window.location.href = "/shipments"
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			});
		}

	}

	handleDelete = event => {
		event.preventDefault();		
		const url="/shipments/"+this.state.id
		fetchAPIDeleteMethod(url)
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					window.location.href="/shipments"
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: Yetkiniz yok`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
		  	})
	}

	loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			let x = this.fetchUsers(inputValue);
		  callback(x);
		}, 1000);
	};

	loadUserOptions = (inputValue, callback) => {
    	if (!inputValue) {
      		return callback([]);
		}
		
		fetchAPIGetMethodWithParams('/users', {
			active: true,
			name: inputValue
		})
		.then(response => {
			let tempUserData = []     
			if (response.status >= 200 && response.status < 299) {
				for (let user of response.data) {
					tempUserData.push({"label": user.name, "value": user.id.toString()})
				}
			}
			else if (response.status >= 300 && response.status < 500) {
				alert(`${response.status} - Bilinmeyen Hata`)
			} 
			else {
				alert(`${response.status} - Bilinmeyen Hata`)
			}
			callback(tempUserData)
			this.setState({ isLoading: false })
		})
	};

	customNoDataRenderer = ({ props, state }) => (
		<p>
		  <strong>{state.search}</strong> numaralı kayıt bulunamadı
		</p>
	);

	promiseOptions = inputValue => new Promise(resolve => {
    setTimeout(() => {
			let x  = this.fetchUsers(inputValue)
      resolve(x);
    }, 500);
	});

	// No options message for react-select

	returnMessage = e => {
		if(e.inputValue === "") {
			return "Filtrelemek için karakter girişi yapınız."
		} else {
			return "Sonuç bulunamadı."
		}
	}

	render() {
		const { isLoading } = this.state;
		if (isLoading) {
            return (
                <Loader />
            )
		} else {
			return (<>
				<div class="row mt-5">
					<div class="col-sm-6">
						<div class="card">
							<div class="card-body">
								<form class="form" onSubmit={this.handleSubmit}>
									<h6 id="env_name">Teslimat ID :</h6>
									<div class="input-group mb-3">
										<input type="text" name="id" value={this.state.id === null ? ' ' : this.state.id} class="form-control" disabled></input>
										<div class="input-group-append">
											<span class="input-group-text"><FaBox /></span>
										</div>
									</div>
									<h6 id="env_name" hidden={this.state.envelope_id === null}>Zarf No :</h6>
									<div class="mb-3" hidden={this.state.envelope_id === null}>
										<input class="form-control" name="envelope_id" value={this.state.envelope_id} onChange={this.handleTextChange}></input>
									</div>
									<h6>Kaynak :</h6>
									<div class="mb-3" hidden={this.state.external_source_user_name !== null}>
										<AsyncSelect 
											placeholder= {this.state.source_user_name === null ? ' ' : this.state.source_user_name}
											loadOptions={this.loadUserOptions} 
											onChange={(values) => this.handleChange(values,"source_user_id")}
											noOptionsMessage={this.returnMessage}
										/>
									</div>
									<h6>Hedef :</h6>
									<div class="mb-3">
										<AsyncSelect 
											placeholder= {this.state.target_user_name === null ? ' ' : this.state.target_user_name}
											loadOptions={this.loadUserOptions} 
											onChange={(values) => this.handleChange(values,"target_user_id")}
											noOptionsMessage={this.returnMessage}
										/>
									</div>
									<div class="text-center mt-3">
										<button class="btn btn-primary float-left" type="submit">Güncelle</button>
									</div>
								</form>							
								<form onSubmit={this.handleDelete}>
									<input type="hidden" name="delete_id" class="form-control" value={this.state.id === null ? ' ' : this.state.id}></input>
									<button class="btn btn-danger float-right" type="submit">Sil</button>
								</form>
							</div>
						</div>
					</div>
				</div>
				</>
			)
		}
		
	}
}