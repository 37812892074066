import React, { Component } from "react";
import TableUsers from "./TableUsers";
import { fetchAPIGetMethodWithParams } from "../../Common/fetchApi";

export default class Userspage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: [],
			totalPages: null,
			isLoading: false,
			filterName: null,
			filterId: null,
			forceUpdateTable: false,
		};
	}

	getData = async (i = 0) => {
		let data = [];
		await fetchAPIGetMethodWithParams("/users", {
			page: i + 1,
			name: this.state.filterName,
			tekfen_id: this.state.filterId,
		}).then((response) => {
			if (response.status >= 200 && response.status < 299) {
				data = response.data;
				this.setState({
					userData: response.data,
					totalPages: parseInt(response.headers["total-pages"]),
					isLoading: false,
				});
			} else {
				alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`);
			}
		});
		return data;
	};

	async componentDidMount() {
		this.setState({ isLoading: true });
	}

	handleFilterChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	filter = () => {
		document.getElementById("resetFilter").hidden = false;
		this.setState({
			forceUpdateTable: !this.state.forceUpdateTable,
		});
	};

	dismissFilter = () => {
		document.getElementById("resetFilter").hidden = true;
		this.setState({
			filterName: null,
			filterId: null,
			forceUpdateTable: !this.state.forceUpdateTable,
		});
	};

	convertRows = (data) => {
		return data.map((value) => {
			return {
				id: value.id,
				name: value.name,
				email: value.email,
				tekfen_id: value.tekfen_id,
			};
		});
	};

	render() {
		return (
			<div class="row">
				<div class="col-sm-9 mb-5 mt-3 bg-white">
					<TableUsers
						getData={this.getData}
						pageCount={this.state.totalPages}
						convertRows={this.convertRows}
						forceUpdateTable={this.state.forceUpdateTable}
						id="myTable"
					/>
					<a
						href="/newuser"
						class="btn btn-success mt-3 mb-3 display-4"
						role="button"
					>
						Yeni Kullanıcı
					</a>
				</div>
				<div class="col-sm-3 mt-3">
					<p>{this.state.filterName}</p>
					<div class="card">
						<div class="card-body">
							<h6 class="card-title border-bottom">Filtre Seçenekleri</h6>
							<div class="mt-4">
								<h6>Adı</h6>
								<input
									className="form-control"
									placeholder="örn. Ali"
									name="filterName"
									id="filterName"
									onChange={this.handleFilterChange}
								></input>
							</div>
							<div class="mt-4">
								<h6>Tekfen Kart ID</h6>
								<input
									type="text"
									className="form-control"
									placeholder="örn. 12345"
									name="filterId"
									id="filterId"
									onChange={this.handleFilterChange}
								></input>
							</div>
							<div class="mt-3 text-center">
								<button
									class="btn btn-primary float-right"
									onClick={this.filter}
								>
									Filtrele
								</button>
							</div>
							<div class="mt-3 text-center">
								<button
									class="btn btn-warning float-left"
									onClick={this.dismissFilter}
									id="resetFilter"
									hidden
								>
									Filtreyi Kaldır
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
