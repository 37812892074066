import React from 'react';
import { TiDelete } from "react-icons/ti";
import { fetchAPIGetMethod, fetchAPIDeleteMethodWithParams } from '../../Common/fetchApi'
import Loader from '../../Common/Loader.jsx'

export default class BadgeEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			roles: this.props.roles,
			organizations: [],
			isLoading: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ roles: nextProps.roles });
	}

	componentDidMount() {		
		this.setState({ isLoading: true });
		fetchAPIGetMethod('/organizations')
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					this.setState({ organizations: response.data })
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			})
			.then(
				this.setState({ isLoading: false })
			);
	}

	removeRole = (val, org_id) => {
		const url = "/users/" + this.props.userID + "/roles"
		fetchAPIDeleteMethodWithParams(url, {
			name: val,
			organization_id: org_id
		})
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					for (var i = 0; i < this.state.roles.length; i++) {
						if (this.state.roles[i].name === val) {
							this.state.roles.splice(i, 1);
							this.setState({ roles: this.state.roles });
						}
					}				
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			})
	}

	render() {
		const { organizations, roles, isLoading } = this.state;

		return (
			<>
				{isLoading ? (
					<Loader />
				) :
					<div class="form-label-group">
						<label>Roller</label>
						<div class="row">
							{roles.map((value, i) => {
								if (value.name === 'secretary') {
									return (
										<div class="mt-2 p-2 badge badge-success badge-roles" key="secretary">{organizations.map((val,i) => {
											if(val.id=== value.organization_id){
												return val.name
											}
											else{
												return null
											}
										})}-Sekreter
											<TiDelete class="removeButton" onClick={() => this.removeRole('secretary', value.organization_id)} />
										</div>
									)
								}
								else if (value.name === 'admin') {
									return (
										<div className="mt-2 p-2 badge badge-danger badge-roles" key="admin">{organizations.map((val,i) => {
											if(val.id=== value.organization_id){
												return val.name
											}
											else{
												return null
											}
										})}-Admin
											<TiDelete className="removeButton" onClick={() => this.removeRole('admin', value.organization_id)} />
										</div>
									)
								}
								else if (value.name === 'postman') {
									return (
										<div class="mt-2 p-2 badge badge-dark badge-roles" key="postman">{organizations.map((val,i) => {
											if(val.id=== value.organization_id){
												return val.name
											}
											else{
												return null
											}
										})}-Postacı
											<TiDelete class="removeButton" onClick={() => this.removeRole('postman', value.organization_id)} />
										</div>
									)
								}
								else if (value.name === 'employee') {
									return (
										<div class="mt-2 p-2 badge badge-warning text-white badge-roles"  key="employee">{organizations.map((val,i) => {
											if(val.id=== value.organization_id){
												return val.name
											}
											else{
												return null
											}
										})}-Çalışan
											<TiDelete class="removeButton" onClick={() => this.removeRole('employee', value.organization_id)} />
										</div>
									)
								}
								else if (value.name === 'agent') {
									return (
										<div class="mt-2 p-2 badge badge-primary badge-roles" key="agent">{organizations.map((val,i) => {
											if(val.id=== value.organization_id){
												return val.name
											}
											else{
												return null
											}
										})}-Temsilci
											<TiDelete class="removeButton" onClick={() => this.removeRole('agent', value.organization_id)} />
										</div>
									)
								}
								else{
									return (
										<div className="ml-3 text-danger">Rol bulunamadı!!!</div>
									)
								}
							})}
						</div>
					</div>
				}
			</>
		)
	}
}