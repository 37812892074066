import React from "react";
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
import Loader from "./Loader";

function Table({
	columns,
	data,
	fetchData,
	isPaginationEnable,
	forceUpdateTable,
	pageCount: controlledPageCount,
	setSelectedRows,
	loading,
}) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		// Get the state from the instance,
		state: { pageIndex, pageSize, selectedRowIds },
	} = useTable(
		{
			autoResetPage: false,
			columns,
			data,
			initialState: {
				pageIndex: 0,
				showPagination: isPaginationEnable,
				pageSize: 20,
			}, // Pass our hoisted table state
			manualPagination: true, // Tell the usePagination
			// hook that we'll handle our own data fetching
			// This means we'll also have to provide our own
			// pageCount.
			pageCount: controlledPageCount,
			autoResetSelectedRows: false,
			getRowId: (row) => row.id,
		},
		useSortBy,
		usePagination,
		useRowSelect
	);

	// Listen for changes in pagination and use the state to fetch our new data

	React.useEffect(() => {
		if (data.length > 0) {
			if (pageIndex === 0) {
				fetchData({ pageIndex, pageSize });
			} else {
				gotoPage(0);
			}
		}
	}, [forceUpdateTable]); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		fetchData({ pageIndex, pageSize });
	}, [fetchData, pageIndex, pageSize]);

	React.useEffect(() => {
		if (typeof setSelectedRows === "undefined") {
			return;
		}
		setSelectedRows(selectedRowIds);
	}, [selectedRowIds]); // eslint-disable-line react-hooks/exhaustive-deps

	// Render the UI for your table
	return (
		<>
			<table
				{...getTableProps()}
				class="table table-hover bg-white border-white"
				id="myTable"
			>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps()}>{column.render("Header")}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.length > 0 || pageIndex > 0 ? (
						page.map((row, i) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td {...cell.getCellProps()}>{cell.render("Cell")}</td>
										);
									})}
								</tr>
							);
						})
					) : (
						<tr className="rt-tr empty-table">
							<td colSpan="10000" className="text-center">
								{loading ? <Loader /> : "Kayıt bulunamadı."}
							</td>
						</tr>
					)}
				</tbody>
			</table>
			<div class="d-flex justify-content-center pagination">
				<button
					class="btn"
					onClick={() => gotoPage(0)}
					disabled={!canPreviousPage}
				>
					{"İlk"}
				</button>{" "}
				<button
					class="btn"
					onClick={() => previousPage()}
					disabled={!canPreviousPage}
				>
					{"Önceki"}
				</button>{" "}
				<input
					value={pageIndex + 1 || 1}
					onChange={(e) => {
						const page = e.target.value ? Number(e.target.value) - 1 : 0;
						gotoPage(page);
					}}
				/>
				<div class="pagination-text">of {pageOptions.length}</div>
				<button class="btn" onClick={() => nextPage()} disabled={!canNextPage}>
					{"Sonraki"}
				</button>{" "}
				<button
					class="btn"
					onClick={() => gotoPage(pageCount - 1)}
					disabled={!canNextPage}
				>
					{"Sonuncu"}
				</button>{" "}
			</div>
		</>
	);
}

function ReactTableWrapper({
	data,
	columns,
	getData,
	showPagination = true,
	convertRows,
	pageCount,
	updatedRow,
	forceUpdateTable,
	setSelectedRows,
  setSelectedOriginalRows
}) {
	const [rows, setRows] = React.useState(data ? data : []);
	const [loading, setLoading] = React.useState(false);
	const fetchIdRef = React.useRef(0);

	if (!showPagination && rows !== data) {
		setRows(data);
	}

	// This will get called when the table needs new data
	const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
		const fetchId = ++fetchIdRef.current;
		setLoading(true);
		if (showPagination && fetchIdRef.current === fetchId) {
			let data = getData(pageIndex);
			data
				.then(function (items) {
					setRows(convertRows(items));
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			setLoading(false);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Table
				columns={columns}
				data={rows}
				fetchData={fetchData}
				updatedRow={updatedRow}
				pageCount={pageCount}
				isPaginationEnable={showPagination}
				forceUpdateTable={forceUpdateTable}
				setSelectedRows={setSelectedRows}
				loading={loading}
			/>
		</>
	);
}

export default ReactTableWrapper;
