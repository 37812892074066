import React from 'react';
import Badge from './BadgeRole';
import { fetchAPIGetMethod, fetchAPIPatchMethodWithParams } from '../../Common/fetchApi'

class Profilepage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			userData:[],
			isLoading: true,
			oldPassword: '',
			newPassword: '',
			confirmPassword: '',
		}
	}

	componentDidMount(){
		fetchAPIGetMethod('/users/me')
			.then(response => {
			  this.setState({ userData: response.data })
			  this.setState({ isLoading: false })
			})
	}

	onChange = e => {
		this.setState({[e.target.name]: e.target.value})
	}

	submitForm = e => {
		e.preventDefault()
		const { newPassword } = this.state
		const user = JSON.parse(localStorage.getItem('user'));
		
		const url = "/users/"+user.id
		fetchAPIPatchMethodWithParams(url, {
			password: newPassword
		})
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					window.location.href="/home"
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			});

	}

	render() {
		const { userData } = this.state;
		return (
			<div class="row mt-5">
				<div class="col-sm-6">
					<div class="card">
						<div class="card-body">
							<h5 class="card-title border-bottom">Kullanıcı Detayları</h5>
							<div class="row">
								<div class="col-sm-3 mt-3">
									<p>Adı : </p>
									<p>E-posta : </p>
									<p>Tekfen ID : </p>
									<p>Roller : </p>
								</div>
								<div class="col-sm-9 mt-3">
									<p> {userData.name}</p>
									<p> {userData.email}</p>
									<p> {userData.tekfen_id}</p>
									<div class="row">
										{userData.roles.map((value, index) => {
											if (value.name === 'secretary') {
												return (
													<Badge role="Sekreter" color={'success'} />
												)
											}
											else if (value.name === 'admin') {
												return (
													<Badge role="Admin" color={'danger'} />
												)
											}
											else if (value.name === 'agent') {
												return (
													<Badge role="Temsilci" color={'primary'} />
												)
											}
											else if (value.name === 'postman') {
												return (
													<Badge role="Postacı" color={'dark'} />
												)
											}
											else if (value.name === 'employee') {
												return (
													<Badge role="Çalışan" color={'warning'} />
												)
											}
											return null;
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="card">
						<div class="card-body">
							<form class="form" onSubmit={this.submitForm}>
								<div class="form-label-group">
									<input type="password" name="oldPassword" class="form-control" placeholder="Mevcut Şifre *" required autofocus onChange={this.onChange}></input>
								</div>
								<div class="form-label-group mt-4">
									<input type="password" name="newPassword" class="form-control" placeholder="Yeni Şifre *" required onChange={this.onChange}></input>
								</div>
								<div class="form-label-group mt-4">
									<input type="password" name="confirmPassword" class="form-control" placeholder="Yeni Şifre (Tekrar) *" required onChange={this.onChange}></input>
								</div>
								<div class="text-center mt-4">
									<button class="btn btn-dark" type="submit">Şifre Değiştir</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Profilepage;