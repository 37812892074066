import React from "react";
import TableCompanies from "./TableCompanies";
import { fetchAPIGetMethodWithParams } from "../../Common/fetchApi";

class Companiespage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			companyData: [],
			isLoading: false,
			totalPages: null,
		};
	}

	getData = async (i = 0) => {
		let data = [];
		await fetchAPIGetMethodWithParams("/companies/shipping", {
			page: i + 1,
		}).then((response) => {
			if (response.status >= 200 && response.status < 299) {
				data = response.data;
				this.setState({
					companyData: response.data,
					totalPages: parseInt(response.headers["total-pages"]),
					isLoading: false,
				});
			} else {
				alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`);
			}
		});
		return data;
	};

	async componentDidMount() {
		this.setState({ isLoading: true });
	}

	convertRows = (data) => {
		return data.map((value) => {
			return {
				id: value.id,
				name: value.name,
			};
		});
	};

	render() {
		return (
			<div class="row">
				<div class="col-sm-9 mb-5 mt-3 bg-white">
					<TableCompanies
						getData={this.getData}
						pageCount={this.state.totalPages}
						convertRows={this.convertRows}
						id="myTable"
					/>
					<a
						href="/newcompany"
						class="btn btn-success mt-3 mb-3 display-4"
						role="button"
					>
						Yeni Kargo Şirketi
					</a>
				</div>
			</div>
		);
	}
}

export default Companiespage;
