import React, { Component } from 'react';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import queryString from 'query-string';
import logo from '../../Images/Tekfen_Holding_EN-gb.png';
import { fetchAPIPostMethod, fetchAPIPatchMethodWithParams } from '../../Common/fetchApi';


export default class PasswordReset extends Component {
	constructor(props) {
		super(props)
		this.state = {
			emptyFields: false,
			passwordsNotMatch: false,
			password: "",
			confirmPassword: "",
			token: null
		}
	}

	componentDidMount(){
		const values= queryString.parse(this.props.location.search)
		this.setState({token: values.token})
	}

	onChange = e => {
		this.setState({[e.target.name]: e.target.value})
	}

	submitForm = e => {
		e.preventDefault()
		if (this.state.password === "" || this.state.confirmPassword=== "") {
			this.setState({ 
				emptyFields: true,
				passwordsNotMatch: false
			})
		}
		else if(this.state.password !== this.state.confirmPassword){
			this.setState({ 
				passwordsNotMatch: true,
				emptyFields: false
			})
		}
		else{

			fetchAPIPostMethod('/oauth/token', {
				grant_type: 'client_credentials'
			})
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					localStorage.setItem('access_token', response.data.access_token);
						
					fetchAPIPatchMethodWithParams("/password", {
						reset_password_token: this.state.token,
						password: this.state.password,
						password_confirmation: this.state.confirmPassword
					})
					.then(response => {
						if (response.status >= 200 && response.status < 299) {
							alert("Şifre başarıyla oluşturuldu")
							window.location.href = '/'
						}
						else {
							alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
						}
					});
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			});
		}
	}
	render() {
		return (
			<div class="container">
				<div class="container-fluid border-white mt-5">
					<div class="row justify-content-center mt-5">
						<div class="col-sm-8 mt-5">
							<div class="d-flex justify-content-center mb-5">
							<img src={logo} alt="Logo" class="d-flex justify-content-center logo-login"/>
							</div>
							<form class="bg-white rounded p-3 login-form" onSubmit={this.submitForm}>
								<FormGroup controlId="password" bsSize="large">
									<FormLabel>Şifre *</FormLabel>
									<FormControl size="lg" type="password" name="password" onChange={this.onChange} />
								</FormGroup>
								<FormGroup controlId="confirm-password" bsSize="large">
									<FormLabel>Şifre Tekrarı *</FormLabel>
									<FormControl size="lg" type="password" name="confirmPassword" onChange={this.onChange} />
								</FormGroup>
								{this.state.emptyFields ? (
									<p className="error-message">Lütfen şifre ve tekrarını girin.</p>
								) : null}
								{this.state.passwordsNotMatch ? (
									<p className="error-message">Girdiğiniz şifreler örtüşmüyor.</p>
								) : null}
								<button type="submit" class="btn btn-secondary mb-2 pr-5 pl-5">Şifre Değiştir</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
