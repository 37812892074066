import React from 'react';
import ActionButtons from '../../Common/ActionButtons';
import ReactTableWrapper from '../../Common/ReactTableWrapper';

function TableCompanies({ data, getData, pageCount, convertRows }) {
	const columns = [
		{
			Header: 'ID',
			accessor: 'id',
		},
		{
			Header: 'Adı',
			accessor: 'name',
		},
		{
			id: 'linkID', //required because accessor is not a string
			Header: '',
			accessor: d => d.id,
			Cell: ({ cell: { value } }) => {
				return (
					<>
						<ActionButtons linkEye={'/companyinfo/' + value} linkEdit={'/companyedit/' + value} />
					</>
				);
			}
		}
	]

	return (
		<ReactTableWrapper
			data ={data}
			columns={columns}
			getData={getData}
			pageCount={pageCount}
			convertRows={convertRows}
		/>
	)
}

export default TableCompanies;