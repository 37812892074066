import React, { Component } from 'react'
import ClipLoader from "react-spinners/ClipLoader";

export default class Loader extends Component {
    render() {
        return (
            <div className="d-flex justify-content-center loader">
                <ClipLoader
                    size={50}
                    color={"rgb(41, 69, 95)"}
                />
            </div>
        )
    }
}
