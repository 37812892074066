import React, { Component } from 'react'
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { fetchAPIPostMethod, fetchAPIGetMethod } from '../../Common/fetchApi'
import logo from '../../Images/Tekfen_Holding_EN-gb.png';

export default class Login extends Component {
	constructor(props) {
		super(props)

		this.state = {
			error: false,
			emailError: false,
			passwordError: false,
			emptyFields: false,
			username: "",
			password: "",
			loggedIn: false,
		}
	}

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value })
	}

	submitForm = e => {
		e.preventDefault()

		if (this.state.username === "" || this.state.password === "") {
			this.setState({
				emptyFields: true,
				emailError: false,
				passwordError: false
			})
		}
		fetchAPIPostMethod('/oauth/token', {
			username: this.state.username,
			password: this.state.password,
			grant_type: 'password'
		})
		.then(response => {
			if (response.status >= 200 && response.status < 299) {
				localStorage.setItem('access_token', response.data.access_token);
				localStorage.setItem('refresh_token', response.data.refresh_token);
				return this.login()
			} 
			this.setState({ 
				emailError: true,
				passwordError: true,
				emptyFields: false
			})	
		});
	}

	login () {
		fetchAPIGetMethod('/users/me')
		.then(response => {
			if (response.status >= 200 && response.status < 299) {
				const user = {
					name: response.data.name,
					email: response.data.email,
					id: response.data.id,
					roles: response.data.roles
				}
				localStorage.setItem('user', JSON.stringify(user))
				this.setState({ loggedIn: true })

				if (user.roles.some(role => role.name === "admin" || role.name === "agent")) {
					return window.location.href="/home"
				}
				if (user.roles.some(role => role.name === "secretary" || role.name === "postman")) {
					return window.location.href="/shipments"
				}
				alert('Yetkiniz yok')
				return window.location.href="/logout"
			}
			this.setState({error: true})
		})
	}

	render() {
		if (this.state.error) {
			alert("Bir hata oluştu. Lütfen bilgilerinizi kontrol edip tekrar deneyin.")
		}
		return (
			<div class="container">
				<div class="container-fluid border-white mt-5">
					<div class="row justify-content-center mt-5">
						<div class="col-sm-8 mt-5">
							<div class="d-flex justify-content-center mb-5">
								<img src={logo} alt="Logo" class="d-flex justify-content-center logo-login"/>
							</div>
							<form class="bg-white rounded p-3 login-form" onSubmit={this.submitForm} >
								<FormGroup controlId="email" bsSize="large">
									<FormLabel>E-posta *</FormLabel>
									<FormControl size="lg" type="text" name="username" value={this.state.username} onChange={this.onChange} />
									{this.state.emailError ? (
										<p className="error-message">Lütfen geçerli bir e-posta adresi girin.</p>
									) : null}
								</FormGroup>
								<FormGroup controlId="password" bsSize="large">
									<FormLabel>Şifre *</FormLabel>
									<FormControl size="lg" type="password" name="password" value={this.state.password} onChange={this.onChange} />
									{this.state.passwordError ? (
										<p className="error-message">Şifre kabul edilemez.</p>
									) : null}
									{this.state.emptyFields ? (
										<p className="error-message">Lütfen bilgilerinizi girin.</p>
									) : null}
								</FormGroup>
								<button type="submit" class="btn btn-secondary mb-2 pr-5 pl-5" >Giriş Yap</button>
								<br></br>
								<a class="forgot-password" href="/password/forgot">Şifremi Unuttum</a>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
