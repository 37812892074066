import React, { Component } from 'react'
import { FaEye } from "react-icons/fa"
import { FaEdit } from "react-icons/fa"

export default class ActionButtons extends Component {
  render() {
    return (
      <>
        <a href={this.props.linkEye} class="mr-3" id="eyeButton"><FaEye /></a>
        <a href={this.props.linkEdit} id="editButton"><FaEdit /></a>
      </>
    )
  }
}