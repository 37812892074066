import React from "react"
import { QRCode } from "react-qr-svg";

export default class ComponentToPrintEnvelopes extends React.Component {

  render() {
    return (<><div id="print" >
      {this.props.selectedRows.map((data) => {
        return (
          <div class="container" key={data}>
            <div class="d-flex align-items-start justify-content-start w-75 mb-3">
              <div class="col-3">
                <QRCode
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="Q"
                  value={'ENV ' + data}
                />
              </div>
              <div class="col-9 d-flex flex-column">
                <div>Zarf No: {data}</div>
              </div>
            </div>
            <div class="page-break" />
          </div>
        )
      })}
    </div>

    </>
    );
  }
}