import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const token = localStorage.getItem("access_token")

const PrivateRoute = ({component: Component, allowed, ...rest}) => {
    return (
        // Show the component only when the user is logged in. Otherwise, redirect the user to login page
        <Route {...rest} render={props => (
            token && allowed ?
                <Component {...props} />
            : token && !allowed ?   
            <Redirect to="/home" />
            :
            <Redirect to="/" />
        )} />
    );
};

export default PrivateRoute;