import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

export default class Logout extends Component {
	constructor(props) {
		super(props)
		localStorage.removeItem('access_token')
		localStorage.removeItem('refresh_token')
		localStorage.removeItem('user')
	}

	render() {
		return <Redirect to="/"></Redirect>
	}
}
