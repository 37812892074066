import React from 'react';
import Envelopespage from './Envelopespage';
import Topbar from '../../Common/Topbar';

class Envelopes extends React.Component {
	render() {
		return (
			<div class="container-fluid">
				<Topbar pageName="Zarflar" />
				<div class="container-fluid border-white">
					<Envelopespage />
				</div>
			</div>
		)
	}
}
export default Envelopes;