import React from "react";
import ActionButtons from "../../Common/ActionButtons";
import ReactTableWrapper from "../../Common/ReactTableWrapper";

const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return (
			<>
				<input type="checkbox" ref={resolvedRef} {...rest} />
			</>
		);
	}
);

function TableEnvelopes({
	data,
	getData,
	pageCount,
	convertRows,
	forceUpdateTable,
  setSelectedRows
}) {
	const columns = [
		{
			id: "selection",
			// The header can use the table's getToggleAllRowsSelectedProps method
			// to render a checkbox
			// Header: ({ getToggleAllRowsSelectedProps }) => (
			//   <div>
			// 	<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
			//   </div>
			// ),
			Header: "Seç",
			Cell: ({ row }) => (
				<div>
					<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
				</div>
			),
		},
		{
			Header: "ID",
			accessor: "id",
		},
		{
			Header: "Statü",
			accessor: "status",
		},
		{
			id: "linkID", //required because accessor is not a string
			Header: "",
			accessor: (d) => d.id,
			Cell: ({ cell: { value } }) => {
				return (
					<>
						<ActionButtons
							linkEye={"/envelopeinfo/" + value}
							linkEdit={"/envelopeedit/" + value}
						/>
					</>
				);
			},
		},
	];

	return (
		<ReactTableWrapper
			data={data}
			columns={columns}
			getData={getData}
			pageCount={pageCount}
			convertRows={convertRows}
			forceUpdateTable={forceUpdateTable}
      setSelectedRows={setSelectedRows}
		/>
	);
}

export default TableEnvelopes;
