import React from 'react';
import Topbar from '../../Common/Topbar.jsx'
import Userspage from './Userspage'

class Users extends React.Component {
	render() {
		return (
			<div class="container-fluid">
				<Topbar pageName="Kullanıcılar" />
				<div class="container-fluid border-white">
					<Userspage />
				</div>
			</div>
		)
	}
}

export default Users;