import React, { Component } from 'react'
import Loader from '../../Common/Loader.jsx'
import BadgeEdit from "./BadgeRoleEdit"
import { fetchAPIGetMethod, fetchAPIPostMethodWithParams } from '../../Common/fetchApi'

export default class RoleSelection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			roles: this.props.roles,
			organizations: [],
			roleOptions: [],
			selectedRole: null,
			selectedOrganization: null
		};
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		fetchAPIGetMethod('/organizations')
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					let roleOptionsMap = [
						{value:'admin', label:"Admin"},
						{value:'secretary', label:"Sekreter"},
						{value:'agent', label:"Temsilci"},
						{value:'postman', label:"Postacı"},
						{value:'employee', label:"Çalışan"}
					]
					this.setState({ 
						organizations: response.data,
						roleOptions: roleOptionsMap
					})
					
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			})
			.then(
				this.setState({ isLoading: false })
			);

	}
	selectRole = e => {
		this.setState({ selectedRole: e.target.value })
	}
	selectOrganization = e => {
		this.setState({ selectedOrganization: e.target.value })
	}
	submitRole = event => {
		event.preventDefault()
		if (event.target[0].value === '' || event.target[1].value === '') {
			return alert('Rol ve şirket seçimi yapmalısınız.')
		}
		const url = "/users/"+this.props.userID+"/roles"
		fetchAPIPostMethodWithParams(url, {
		  name: this.state.selectedRole ,
		  organization_id: this.state.selectedOrganization
		})
		.then(response => {
			if (response.status >= 200 && response.status < 299) {
				fetchAPIGetMethod('/users/'+this.props.urlid)
				.then(response => {
					this.setState({ roles: response.data.roles })
				})
			}
			else if (response.status >= 300 && response.status < 500) {
				alert(`${response.status} Hatası: Yetkiniz yok`)
			} 
			else {
				alert(`${response.status} - Bilinmeyen Hata`)
			}	
		})
	}

	render() {
		const { organizations, roleOptions, isLoading } = this.state;

		return (<>
			{isLoading ? (
				<Loader />
			) : 
			<div className="col-sm-6">
				<div className="card">
					<div className="card-body">
						<BadgeEdit roles={this.state.roles} userID={this.props.userID}/>
						<form class="form" onSubmit={this.submitRole}>
							<div className="row mt-5">
								<div className="col-sm-6">
									<div className="form-label-group w-100">
										<label>Rol Ekle</label>
										<select className="form-control" name="role" onChange={this.selectRole}>
											<option key="select-role" value=''>Rol Seç</option>
											{roleOptions.map(role => {
												return (<option key={role.value} value={role.value}>{role.label}</option>)
											})}
										</select>
									</div>
								</div>
								<div className="col-sm-6">
									<div className="form-label-group w-100">
										<label>Şirket Ekle</label>
										<select className="form-control" name="org" onChange={this.selectOrganization}>
											<option key="select-organization" value=''>Şirket Seç</option>
											{organizations.map(organization => {
												return (<option key={organization.id} value={organization.id}>{organization.name}</option>)
											})}
										</select>
									</div>
								</div>
							</div>
							<button type="submit" className="btn btn-success mt-2 w-100">Rol Ekle</button>
						</form>
					</div>
				</div>
			</div>
			}
		</>)
	}
}