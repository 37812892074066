import React from 'react';
import Loader from '../../Common/Loader'
import { fetchAPIGetMethod } from '../../Common/fetchApi'

class Userinfopage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: [],
			isLoading: true,
			id: null,
			tekfen_id: null,
			user_name: null,
			email: null,
			active: null
		};
	}
	
	componentDidMount() {
		const userId = this.props.id;
		// fetchAPIGetMethodWithParams('/users', {
		// 	tekfen_id: userId
		// })
		// 	.then(response => {
		// 		this.setState({ userData: response.data[0] })
		// 		this.setState({ isLoading: false })
		//   	})
		fetchAPIGetMethod('/users/' + userId)
		.then(response => {
			this.setState({ 
				userData: response.data,
				id: response.data.id || null,
				tekfen_id: response.data.tekfen_id || null,
				name: response.data.name || null,
				email: response.data.email || null
			})
			if (response.data.active === true) {
				this.setState({ active: 'Evet' })
			} else {
				this.setState({ active: 'Hayır' })
			}
			this.setState({ isLoading: false })
		})
	}
	
	render() {
		const { isLoading } = this.state;
		if (isLoading) {
			return (
				<Loader />
			)
		}
		return (
			<div class="row mt-5">
				<div class="col-sm-6">
					<div class="card">
						<div class="card-body">
							<h5 class="card-title border-bottom">Kullanıcı Detayları</h5>
							<div class="row">
								<div class="col-sm-4">
									<p>Adı : </p>
									<p>E-posta : </p>
									<p>Tekfen Kart ID : </p>
									<p>Aktif : </p>
								</div>
								<div class="col-sm-8">
									<p>{this.state.name}</p>
									<p>{this.state.email}</p>
									<p>{this.state.id}</p>
									<p>{this.state.active}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Userinfopage;