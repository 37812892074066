import React from 'react';
import Newcompanypage from './Newcompanypage';
import Topbar from '../../Common/Topbar';

class Newcompany extends React.Component {
	render() {
		return (
			<div class="container-fluid">
				<Topbar pageName="Yeni Kargo Şirketi" />
				<div class="container-fluid border-white">
					<Newcompanypage />
				</div>
			</div>
		)
	}
}

export default Newcompany;