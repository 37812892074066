import React from 'react';
import { Link } from 'react-router-dom';
import { FaUserAlt } from "react-icons/fa";
import { FaSignOutAlt } from "react-icons/fa";

class Topbar extends React.Component {

	render() {
		const userName = JSON.parse(localStorage.getItem('user')).name;

		return (
			<div className="row bg-white border-bottom">
				<div className="mt-3 ml-3 col-sm-9">
					<p class="page-name">{this.props.pageName}</p>
				</div>
				<div className="ml-5 mt-3 col-sm-2 align-right">
					<div class="btn-group">
						<button class="btn dropdown-toggle float-right" data-toggle="dropdown">{userName} <span class="caret"></span></button>
						<div class="dropdown-menu">
							<Link to="/profile" className="dropdown-item"><FaUserAlt /> Profil</Link>
							<Link to="/logout" className="dropdown-item"><FaSignOutAlt /> Çıkış Yap</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Topbar;