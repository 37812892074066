import React from 'react';
import EnvelopeEditPage from './EnvelopeEditPage';
import Topbar from '../../Common/Topbar';

class EnvelopeEdit extends React.Component {

  render() {
    return (
      <div class="container-fluid">
        <Topbar pageName="Zarf Düzenle" />
        <div class="container-fluid border-white">
          <EnvelopeEditPage urlid={this.props.match.params.id} />
        </div>
      </div>
    )
  }
}
export default EnvelopeEdit;