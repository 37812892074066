import React from 'react';
import Newenvelopepage from './Newenvelopepage';
import Topbar from '../../Common/Topbar';

class Newenvelope extends React.Component {
	render() {
		return (
			<div class="container-fluid">
				<Topbar pageName="Yeni Zarf" />
				<div class="container-fluid border-white">
					<Newenvelopepage />
				</div>
			</div>
		)
	}
}

export default Newenvelope;