import React from 'react';
import Envelopeinfopage from './Envelopeinfopage';
import Topbar from '../../Common/Topbar';

class Envelopeinfo extends React.Component {
	render() {
		return (
			<div class="container-fluid">
				<Topbar pageName="Zarf Detay" />
				<div class="container-fluid border-white">
					<Envelopeinfopage urlid={this.props.match.params.id} />
				</div>
			</div>
		)
	}
}

export default Envelopeinfo;