import React from 'react';
import Newuserpage from './Newuserpage';
import Topbar from '../../Common/Topbar';

class Newuser extends React.Component {
	render() {
		return (
			<div class="container-fluid">
				<Topbar pageName="Yeni Kullanıcı" />
				<div class="container-fluid border-white">
					<Newuserpage />
				</div>
			</div>
		)
	}
}

export default Newuser;