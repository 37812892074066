import React, { Component } from 'react'
import Shipmentinfopage from './Shipmentinfopage';
import Topbar from '../../Common/Topbar';

export default class Shipmentinfo extends Component {
    render() {
        return (
            <div class="container-fluid">
                <Topbar pageName="Teslimat Detay" />
                <div class="container-fluid border-white">
                    <Shipmentinfopage urlid={this.props.match.params.id}/>
                </div>
            </div>
        )
    }
}