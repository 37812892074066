import React from 'react';
import { fetchAPIPostMethodWithParams, fetchAPIGetMethod } from '../../Common/fetchApi';

class Newenvelopepage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			// nfc_id: null,
			organization_id: null,
			status: null,
			organizations: [],
			selectedOrganization: null
		}
	}

	componentDidMount(){
		fetchAPIGetMethod('/organizations')
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					this.setState({ organizations: response.data })
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			})
	}

	selectOrganization = e => {
		this.setState({ selectedOrganization: e.target.value })
	}

	onChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	submitForm = e => {
		e.preventDefault()
		const { selectedOrganization } = this.state
		// const { nfc_id, selectedOrganization, status } = this.state

		const randomNumber = getRandomInt(1,999999)
		fetchAPIPostMethodWithParams('/envelopes', {
			// nfc_id: nfc_id,
			// tekfen_id: nfc_id,
			nfc_id: randomNumber,
			tekfen_id: randomNumber,
			organization_id: selectedOrganization,
			status: "available",
		})
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					window.location.href = "/envelopes"
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			});
	}

	render() {
		const { organizations } = this.state;
		return (
			<div class="row mt-5">
				<div class="col-sm-6">
					<div class="card">
						<div class="card-body">
							<form class="form" onSubmit={this.submitForm}>
								<div class="input-group mt-4">
									<select class="form-control" name="org" onChange={this.selectOrganization}>
										<option selected disabled>Şirket Seç</option>
										{organizations.map(value => {
											return (<option value={value.id} >{value.name}</option>)
										})}
									</select>
								</div>
								<div class="text-center mt-3">
									<button class="btn btn-success float-left" type="submit">Zarf Ekle</button>
								</div>
							</form>
						</div>
					</div>
				</div>

			</div>

		)
	}
}

function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

export default Newenvelopepage;

/**
 * 									<select class="form-control" name="org" onChange={this.selectOrganization}>
										<option selected disabled>Şirket Seç</option>
										<option value={organizations.id}>{organizations.name}</option>
									</select>
 */
