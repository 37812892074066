import React, { Component } from 'react'
import Widget from './Widget'
import { fetchAPIGetMethod } from '../../Common/fetchApi'

export default class Homepage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            stats: [],
            averageWait: 0,
            averageTransit: 0,
            delivered: 0,
            waiting: 0,
            transiting: 0
        }
    }
    
    componentDidMount() {
        this.fetchStats()
    }

    fetchStats = () => {
        fetchAPIGetMethod('/shipments/stats')
        .then(response => {
            if (response.status >= 200 && response.status < 299) {
                this.setState({ 
                    stats: response.data,
                    delivered: response.data.delivered,
                    waiting: response.data.waiting,
                    transiting: response.data.transiting,
                    averageWait: response.data.average_wait_duration,
                    averageTransit: response.data.average_transit_duration
                });
                if(response.data.average_transit_duration != null && response.data.average_wait_duration != null){
                    this.calculateTime()
                }
            }
            else {
                alert(`${response.status} Hatası: ${response?.data?.full_messages[0]}`)
            }
        });
    }

    calculateTime = () => {
        var wait_hour = parseInt(this.state.stats.average_wait_duration.substring(0,2))
        var wait_minute = parseInt(this.state.stats.average_wait_duration.substring(3,5))
        var wait_total = ( wait_hour * 60 ) + wait_minute
        this.setState({averageWait : wait_total})

        var transit_hour = parseInt(this.state.stats.average_transit_duration.substring(0,2))
        var transit_minute = parseInt(this.state.stats.average_transit_duration.substring(3,5))
        var transit_total = ( transit_hour * 60 ) + transit_minute
        this.setState({averageTransit : transit_total})
    }
    
    render() {
        return (
            <div className="row text-center">
                <Widget name="Tamamlanan" count={this.state.delivered} unit="Teslimat" />
                <Widget name="Bekleyen" count={this.state.waiting} unit="Teslimat" />
                <Widget name="Yolda" count={this.state.transiting} unit="Teslimat" />
                <Widget name="Ortalama Bekleme Süresi" count={this.state.averageWait} unit="Dakika" />
                <Widget name="Ortalama Taşıma Süresi" count={this.state.averageTransit} unit="Dakika" />
            </div>
        )
    }
}