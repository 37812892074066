import React from 'react';
import Loader from '../../Common/Loader.jsx';
import { fetchAPIGetMethod, fetchAPIGetMethodWithParams } from '../../Common/fetchApi';

class Companyinfopage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			companyData: [],
			tempData: [],
			totalPages: null,
			id: null,
			name: null,
			isLoading: true
		};
	}

	getHeaderInfo = async () =>{	
		return new Promise(resolve => {
			fetchAPIGetMethod('/companies/shipping')
				.then(response => {
					this.setState({totalPages : parseInt(response.headers["total-pages"])})
					resolve(true);
				})
			})
	}

	getData = async i => {
		return new Promise(resolve => {
			fetchAPIGetMethodWithParams('/companies/shipping', {
				page: i
			})
				.then(response => {
					if (response.status >= 200 && response.status < 299) {
						this.setState({ tempData: this.state.tempData.concat(response.data) })
						this.setState({
							companyData: this.state.tempData
						})
						resolve(true);
					}
					else {
						alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
					}
				})
		})
	}

	result = async () => {
		for (let i = 1; i < this.state.totalPages+1; i++) {
			await this.getData(i);
		}
		this.setState({ isLoading: false })
	}

	async componentDidMount() {
		await this.getHeaderInfo()
		let companyId = parseInt(this.props.urlid);
		await this.result()

		this.state.companyData.forEach(item => {
			if (item.id === companyId) {
				this.setState({ id: item.id, name: item.name })
			}
		})
	}

	render() {
		const { isLoading } = this.state;
		if (isLoading) {
			return (
				<Loader />
			)
		}
		return (
			<div class="row mt-5">
				<div class="col-sm-6">
					<div class="card">
						<div class="card-body">
							<h5 class="card-title border-bottom">Kargo Şirketi Detayları</h5>
							<div class="row">
								<div class="col-sm-4">
									<p>Kargo Şirketi ID : </p>
									<p>Adı : </p>
								</div>
								<div class="col-sm-8">
									<p>{this.state.id}</p>
									<p>{this.state.name}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Companyinfopage;