import React from 'react';
import { FaEnvelope, FaAdjust } from "react-icons/fa";
import { fetchAPIGetMethod, fetchAPIPatchMethodWithParams, fetchAPIDeleteMethod } from '../../Common/fetchApi';
import Loader from '../../Common/Loader.jsx';

class EnvelopeEditPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			envelopeData: [],
			tempData: [],
			id: null,
			status: null
		}
	}

	componentDidMount() {		
		let url = '/envelopes/'+this.props.urlid;
		fetchAPIGetMethod(url)
		  	.then(response => {
				if (response.status >= 200 && response.status < 299) {
					this.setState({ 
						id: response.data.id,
						status: response.data?.status
					})
          			this.setState({ isLoading: false })
				  }
				  else {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				}
		  	})
	  }

	handleDelete = e => {
		e.preventDefault();
		const url = "/envelopes/" + this.state.id
		fetchAPIDeleteMethod(url)
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					window.location.href = "/envelopes"
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: Yetkiniz yok`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			})
	}

	handleRetire = e => {
		e.preventDefault();
		const url = "/envelopes/" + this.state.id
		fetchAPIPatchMethodWithParams(url, {
			available: false
		})
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					window.location.href = "/envelopes"
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			})
	}

	handleResurrect = e => {
		e.preventDefault();
		const url = "/envelopes/" + this.state.id
		fetchAPIPatchMethodWithParams(url, {
			available: true
		})
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					window.location.href = "/envelopes"
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			})
	}

	render() {
		const { isLoading } = this.state;
		if (isLoading) {
			return (
				<Loader />
			)
		}
		return (<>
			<div class="row mt-5">
				<div class="col-sm-6">
					<div class="card">
						<div class="card-body">
							<form class="form" onSubmit={this.handleSubmit}>
								<h6>Zarf ID :</h6>
								<div class="input-group mb-3">
									<input type="text" name="id" value={this.state.id} class="form-control" disabled></input>
									<div class="input-group-append">
										<span class="input-group-text"><FaEnvelope /></span>
									</div>
								</div>
								<h6>Statü :</h6>
								<div class="input-group mb-3">
									<input type="text" name="id" value={
										(() => {
											switch (this.state.status) {
											case "available":   return "Uygun";
											case "in_use": return "Kullanımda";
											case "not_available":  return "Uygun Değil";
											default:      return this.state.status;
											}
										})()
									} class="form-control" disabled></input>
									<div class="input-group-append">
										<span class="input-group-text"><FaAdjust /></span>
									</div>
								</div>
							</form>
							<form onSubmit={this.handleDelete}>
								<input type="hidden" name="idd" class="form-control" value={this.state.id}></input>
								<button class="btn btn-danger float-right" type="submit">Sil</button>
							</form>
							<form onSubmit={this.handleRetire} hidden={this.state.status !== "available"}>
								<input type="hidden" name="idd" class="form-control" value={this.state.id}></input>
								<button class="btn btn-warning float-left" type="submit">Kullanımdan Kaldır</button>
							</form>
							<form onSubmit={this.handleResurrect} hidden={this.state.status !== "not_available"}>
								<input type="hidden" name="idd" class="form-control" value={this.state.id}></input>
								<button class="btn btn-primary float-left" type="submit">Kullanıma Al</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
		)
	}
}

export default EnvelopeEditPage;