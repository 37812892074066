import React, { Component } from 'react'
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import logo from '../../Images/Tekfen_Holding_EN-gb.png';
import { fetchAPIPostMethod, fetchAPIPostMethodWithParams } from '../../Common/fetchApi'


export default class ForgotPassword extends Component {
	constructor(props) {
		super(props)
		this.state = {
			emptyFields: false,
			email: ''
		}
	}
  	onChange = e => {
    	this.setState({[e.target.name]: e.target.value})
  	}
  	submitForm = e => {
		var redirect_url = process.env.REACT_APP_BACKOFFICE_URL+"/password/reset"
		e.preventDefault()
		if (this.state.email === "") {
			this.setState({ emptyFields: true })
		}
		else{
			fetchAPIPostMethod('/oauth/token', {
				grant_type: 'client_credentials'
			})
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					localStorage.setItem('access_token', response.data.access_token);
						
					fetchAPIPostMethodWithParams('/password', {
						email: this.state.email,
						redirect_url: redirect_url
					})
					.then(response => {
						if (response.status >= 200 && response.status < 299) {
							alert("E-posta Gönderildi")
							window.location.href = '/'
						}
						else {
							alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
						}
					});
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			});
		}
 	}
	render() {
		return (
			<div class="container">
				<div class="container-fluid border-white mt-5">
					<div class="row justify-content-center mt-5">
						<div class="col-sm-8 mt-5">
							<div class="d-flex justify-content-center mb-5">
							<img src={logo} alt="Logo" class="d-flex justify-content-center logo-login" />
							</div>
							<form class="bg-white rounded p-3 login-form" onSubmit={this.submitForm}>
								<FormGroup controlId="email" bsSize="large">
									<FormLabel>E-posta *</FormLabel>
									<FormControl size="lg" type="text" name="email" onChange={this.onChange}/>
								</FormGroup>
								{this.state.emptyFields ? (
									<p className="error-message">Lütfen e-posta adresi girin.</p>
								) : null}
								<button type="submit" class="btn btn-secondary mb-2 pr-5 pl-5">E-posta Gönder</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
