import React from 'react';
import UserEditPage from './UserEditPage';
import Topbar from '../../Common/Topbar';

class UserEdit extends React.Component {
	render() {
		return (
			<div class="container-fluid">
				<Topbar pageName="Kullanıcı Düzenle" />
				<div class="container-fluid border-white">
					<UserEditPage urlid={this.props.match.params.id} />
				</div>
			</div>
		)
	}
}
export default UserEdit;