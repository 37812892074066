import React from 'react';
import RoleSelection from "./RoleSelection.jsx";
import Loader from '../../Common/Loader.jsx';
import { FaEnvelope, FaShieldAlt, FaUserAlt } from "react-icons/fa";
import { fetchAPIGetMethod, fetchAPIPatchMethodWithParams, fetchAPIPostMethodWithParams, } from '../../Common/fetchApi'

class UserEditPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: [],
			isLoading: true,
		};
	}

	componentDidMount() {
		fetchAPIGetMethod('/users/' + this.props.urlid)
		.then(response => {
			this.setState({
				userData: response.data,
				id: response.data?.id || null,
				email: response.data?.email || null,
				tekfen_id: response.data?.tekfen_id || null,
				name: response.data?.name || null,
				active: response.data?.active
			 })
			this.setState({ isLoading: false })
		})
	}

	handleChange = e => {
		let field = 'updated_'+e.target.name
		this.setState({
			[field]: e.target.value,
			[e.target.name]: e.target.value
		})
	}

	handleSubmit = event => {
		event.preventDefault();

		const url = '/users/' + this.state.userData.id
		fetchAPIPatchMethodWithParams(url, {
			email: this.state.updated_email,
			tekfen_id: this.state.updated_tekfen_id,
			name: this.state.updated_name
		})
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					window.location.href = "/users"
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: İşlem Başarısız`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			});
	}

	handleDeactivate = event => {

		event.preventDefault();
		const url = "/users/" + this.state.id + '/deactivate'
		fetchAPIPostMethodWithParams(url)
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					window.location.reload();
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: İşlem Başarısız`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			})
	}

	handleActivate = event => {

		event.preventDefault();
		const url = "/users/" + this.state.id + '/activate'
		fetchAPIPostMethodWithParams(url)
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					window.location.reload();
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası:  İşlem Başarısız`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			})
	}

	render() {
		const { isLoading, userData, email, tekfen_id, name, active } = this.state;
		if (isLoading) {
			return (
				<Loader />
			)
		}
		return (
			<>
				<div class="row mt-5">
					<div class="col-sm-6">
						<div class="card">
							<div class="card-body">
								<form class="form" onSubmit={this.handleSubmit}>
									<div class="input-group mt-4">
										<input type="text" name="email" class="form-control" value={email} onChange={this.handleChange}></input>
										<div class="input-group-append">
											<span class="input-group-text"><FaEnvelope /></span>
										</div>
									</div>
									<div class="input-group mt-4">
										<input type="text" name="tekfen_id" class="form-control" value={tekfen_id} onChange={this.handleChange}></input>
										<div class="input-group-append">
											<span class="input-group-text"><FaShieldAlt /></span>
										</div>
									</div>
									<div class="input-group mt-4">
										<input type="text" name="name" class="form-control" value={name} onChange={this.handleChange}></input>
										<div class="input-group-append">
											<span class="input-group-text"><FaUserAlt /></span>
										</div>
									</div>
									<div class="text-center mt-4">
										<input class="btn btn-primary float-left" type="submit" value="Güncelle"></input>
									</div>
								</form>
								<form onSubmit={this.handleDeactivate} hidden={active === false}>
									<input class="btn btn-danger float-right" type="submit" value="Pasifleştir"></input>
								</form>
								<form onSubmit={this.handleActivate} hidden={active === true}>
									<input class="btn btn-success float-right" type="submit" value="Aktifleştir"></input>
								</form>
							</div>
						</div>
					</div>
					<RoleSelection roles={userData.roles} userID={userData.id} urlid={this.props.urlid} />
				</div>
			</>
		)
	}
}
export default UserEditPage;