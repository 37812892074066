import React from 'react';
import { FaDolly } from "react-icons/fa";
import { fetchAPIPostMethodWithParams } from '../../Common/fetchApi';

class Newcompanypage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			name: null,
		}
	}

	onChange = e => {
		this.setState({[e.target.name]: e.target.value})
	}

	submitForm = e => {
		e.preventDefault()

		fetchAPIPostMethodWithParams('/companies/shipping', {
			name: this.state.name
		})
			.then(response => {
				if (response.status >= 200 && response.status < 299) {
					window.location.href = "/companies"
				}
				else if (response.status >= 300 && response.status < 500) {
					alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
				} 
				else {
					alert(`${response.status} - Bilinmeyen Hata`)
				}	
			});
	}

	render() {
		return (
			<div class="row mt-5">
				<div class="col-sm-6">
					<div class="card">
						<div class="card-body">
							<form class="form" onSubmit={this.submitForm}>
								<div class="input-group">
									<input name="name" class="form-control" placeholder="Kargo Şirketi Adı" required onChange={this.onChange}></input>
									<div class="input-group-append">
										<span class="input-group-text"><FaDolly /></span>
									</div>
								</div>
								<div class="text-center mt-3">
									<button class="btn btn-success float-left" type="submit">Kargo Şirketi Ekle</button>
								</div>
							</form>
						</div>
					</div>
				</div>

			</div>

		)
	}
}
export default Newcompanypage;