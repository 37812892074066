import React from "react";
import ActionButtons from "../../Common/ActionButtons";
import ReactTableWrapper from "../../Common/ReactTableWrapper";

const IndeterminateShipmentCheckbox = React.forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return (
			<>
				<input type="checkbox" ref={resolvedRef} {...rest} />
			</>
		);
	}
);

function TableShipments({
	data,
	getData,
	pageCount,
	convertRows,
	forceUpdateTable,
  setSelectedRows
}) {
	const columns = [
		{
			id: "selection",
			// The header can use the table's getToggleAllRowsSelectedProps method
			// to render a checkbox
			// Header: ({ getToggleAllRowsSelectedProps }) => (
			// 	<div>
			// 	<IndeterminateShipmentCheckbox {...getToggleAllRowsSelectedProps()} />
			// 	</div>
			// ),
			Header: "Seç",
			accessor: (d) => d,
			// The cell can use the individual row's getToggleRowSelectedProps method
			// to the render a checkbox
			Cell: (props) => {
				let checkbox = "";
				if (props.row.original.internal === false) {
					checkbox = (
						<IndeterminateShipmentCheckbox
							{...props.row.getToggleRowSelectedProps()}
						/>
					);
				}
				return <div>{checkbox}</div>;
			},
		},
		{
			Header: "ID",
			accessor: "id",
		},
		{
			Header: "Tür",
			accessor: (d) => d,
			Cell: (props) => {
				let cellValue = "";
				if (
					props.row.original.internal === true &&
					props.row.original.confidential === false
				) {
					cellValue = "İç";
				} else if (props.row.original.confidential === true) {
					cellValue = "Özel";
				} else {
					cellValue = "Dış";
				}
				return (
					<>
						<p>{cellValue}</p>
					</>
				);
			},
		},
		{
			Header: "Zarf",
			accessor: "envelope.id",
		},
		{
			id: "sourceID", //required because accessor is not a string
			Header: "Kaynak",
			accessor: (d) => d,
			Cell: ({ cell: { value } }) => {
				if (value?.external_source_user_name) {
					return (
						<>
							<p>{value.external_source_user_name}</p>
						</>
					);
				} else if (value?.source_user.active === false) {
					return (
						<>
							<p>{value.source_user.name} (Pasif)</p>
						</>
					);
				} else {
					return (
						<>
							<p>{value.source_user.name}</p>
						</>
					);
				}
			},
		},
		{
			id: "targetID", //required because accessor is not a string
			Header: "Hedef",
			accessor: (d) => d.target_user,
			Cell: ({ cell: { value } }) => {
				if (value.active === true) {
					return (
						<>
							<p>{value.name}</p>
						</>
					);
				} else {
					return (
						<>
							<p>{value.name} (Pasif)</p>
						</>
					);
				}
			},
		},
		{
			id: "linkID", //required because accessor is not a string
			Header: "",
			accessor: (d) => d.id,
			Cell: ({ cell: { value } }) => {
				return (
					<>
						<ActionButtons
							linkEye={"/shipmentinfo/" + value}
							linkEdit={"/shipmentedit/" + value}
						/>
					</>
				);
			},
		},
	];

	return (
		<ReactTableWrapper
			data={data}
			columns={columns}
			getData={getData}
			pageCount={pageCount}
			convertRows={convertRows}
			forceUpdateTable={forceUpdateTable}
      setSelectedRows={setSelectedRows}
		/>
	);
}

export default TableShipments;
