import React from 'react';
import Loader from '../../Common/Loader.jsx';
import { fetchAPIGetMethod } from '../../Common/fetchApi';

class Envelopeinfopage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			envelopeData: [],
			tempData: [],
			id: null,
			status: null,
			isLoading: true
		};
	}

	componentDidMount() {
		let url = '/envelopes/'+this.props.urlid;
		fetchAPIGetMethod(url)
		.then(response => {
			if (response.status >= 200 && response.status < 299) {
				this.setState({ 
					id: response.data.id || "-",
					status: response.data.status || "-"
				})
				this.setState({ isLoading: false })
			}
			else {
				alert(`${response.status} Hatası: ${response?.data.full_messages[0]}`)
			}
		})
	}

	render() {
		if (this.state.isLoading) {
			return (
				<Loader />
			)
		}
		return (
			<div class="row mt-5">
				<div class="col-sm-6">
					<div class="card">
						<div class="card-body">
							<h5 class="card-title border-bottom">Zarf Detayları</h5>
							<div class="row">
								<div class="col-sm-4">
									<p>Zarf ID : </p>
									<p>Statü : </p>
								</div>
								<div class="col-sm-8">
									<p>{this.state.id}</p>
									<p>
										{(() => {
											switch (this.state.status) {
											case "available":   return "Uygun";
											case "in_use": return "Kullanımda";
											case "not_available":  return "Uygun Değil";
											default:      return this.state.status;
											}
										})()}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Envelopeinfopage;