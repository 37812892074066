import React from 'react';

export default class Badge extends React.Component {
	render() {
		const role = this.props.role;
		return (
			<div class="ml-3 mb-4">
				<span class={"pb-2 pt-2 badge badge-" + (this.props.color)}>{role}</span>
			</div>
		)
	}
}